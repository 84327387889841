import React, { useState, useEffect, useRef } from "react";
import {
  StatusCard,
  DashboardMainFlex,
  DashboardMainBody,
  BalanceCard,
  AppMainBody,
  DescHeader,
  FlexedBtn,
  DashboardInput,
  TableWrap,
  TableFlex,
  SmallTableSpace,
  TableName,
  Line,
  Role,
  DateWrap,
  MenuSpace,
  UserCard,
  IconCard,
  BasicFlex,
  GreyText,
  UserProfileCard,
  GridTexts,
  ThreeSpaceGridWrap,
  SpaceWrap,
  HeroCover,
  PaginationDiv,
  HeaderItems,
  IconBtn,
} from "../../styles/reusable/index";
import SideBarWidget from "../mPos/reusable/sidebar";
import Header from "../mPos/reusable/header";
import * as Icon from "iconsax-react";
import * as FeatherIcon from "react-feather";
import moment from "moment";
import { useParams, useNavigate } from "react-router";
import axios from "axios";
import Loader from "../mPos/reusable/loader";
import { Link } from "react-router-dom";
import CommaNumber from "comma-number";
import EmptyState from "../mPos/reusable/emptyState";
import { AvatarImage } from "../mPos/reusable/style";
import BreadcrumbArea from "../mPos/reusable/breadcrumb";
import { handleBg, handleColor } from "../../utils/colorHandle";
import { getKycStatus } from "../../utils/getKycStatus";
import { axiosInstance } from "../../services/AxiosInstance";
import Pagination from "@mui/material/Pagination";
import CsvDownloader from "react-csv-downloader";
import RangeModal from "../mPos/reusable/rangeModal";

const UserTransactionComp = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const date = new Date();
  const loggedAdmin = localStorage.getItem("tks") || "{}";
  const exportButton: any = useRef(null);

  // States
  const [userInfo, setUserInfo] = useState<any>({});
  const [searchedItem, setSearchedItem] = useState("");
  const [totalData, setTotalData] = useState<string | number | any>("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openExport, setOpenExport] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [onProcess, setOnProcess] = useState(false);
  const [account, setAccount] = useState<any>({});
  const [bvn, setBvn] = useState("");
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(0);
  const [limitPerPage, setLimitPerPage] = useState<number>(0);

  // States
  const [transactions, setTransactions] = useState<any>("");
  const [transactionsMutable, setMutableTransactions] = useState<any>([]);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);

  const totalItems = totalTransactions;

  // Get Transactions
  const getTransaction = async () => {
    await axiosInstance
      .get(`/admin/transaction/business/${id}?limit=5&page=${page}`)
      .then((res) => {
        setTransactions(res.data.data.data);
        setTotalTransactions(res.data.data.total);
        setLimitPerPage(res.data.data.limit);
        setItemsPerPage(res.data.data.data.length);
        setMutableTransactions(res.data.data.data);
      })
      .catch((err) => {
        if (err.response.data.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    getTransaction();
  }, [id, page, navigate]);

  const handleChange = async (e: any, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (id) {
      axiosInstance
        .get(`/admin/business/${id}`)
        .then((res) => {
          setUserInfo(res.data.data);
          setAccount(res.data.data.Accounts[0]);
          setBvn(res.data.data.Bvn[0].data.idNumber);
        })
        .catch((err) => {});
    }
  }, [id]);

  //

  // Export Range

  const body = {
    headers: {
      "Content-Type": "application/json",
      "x-token": `${loggedAdmin}`,
    },
  };

  const getRangeData = (e: any) => {
    e.preventDefault();
    if (startDate && endDate) {
      setOnProcess(true);

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/range/transaction/${new Date(
            startDate
          ).getTime()}/${new Date(endDate).getTime()}`,
          body
        )
        .then((res) => {
          setOnProcess(false);
          if (res.data.statement.length > 0) {
            setExportData(res.data.statement);
          }
        })
        .catch((err) => {
          setOnProcess(false);
          if (err.response.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  // Search Endpoint
  const searchItem = () => {
    if (searchedItem) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/search/business/transaction/${id}?key=${searchedItem}`,
          body
        )
        .then((res) => {
          setTransactions(res.data.data.data);
          setTotalTransactions(res.data.data.total);
          setLimitPerPage(res.data.data.limit);
          setItemsPerPage(res.data.data.data.length);
          setMutableTransactions(res.data.data.data);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click();
      setOpenExport(false);
    }
  }, [exportData]);

  // Refresh Filters
  const refreshFilter = () => {
    setSearchedItem("");
    getTransaction();
  };

  return (
    <>
      {openExport ? (
        <RangeModal
          closeFunc={() => setOpenExport(false)}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          action={getRangeData}
          onProcess={onProcess}
        />
      ) : null}
      <DashboardMainFlex>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <BreadcrumbArea navName="User" redirectUrl="/users" />
            <DescHeader>
              <h3>User</h3>
            </DescHeader>
            <TableWrap
              style={{
                padding: "0.5rem 15px 0 15px",
                borderRadius: "30px 30px 0 0",
              }}
            >
              <HeroCover></HeroCover>
              <TableFlex>
                <SmallTableSpace>
                  <Link to={`/profile/${id}`}>
                    <h3>Profile</h3>
                  </Link>
                </SmallTableSpace>
                <DateWrap>
                  <Link to={`/profile/account-information/${id}`}>
                    <h3>Account Information</h3>
                  </Link>
                </DateWrap>
                <DateWrap>
                  <h3
                    style={{
                      color: "#3E3E0D",
                    }}
                  >
                    Transactions
                  </h3>
                </DateWrap>
                <SmallTableSpace>
                  <Link to={`/profile/kyc/${id}`}>
                    <h3>KYC</h3>
                  </Link>
                </SmallTableSpace>
                <DateWrap>
                  <Link to={`/profile/complain-log/${id}`}>
                    <h3>Complain Log</h3>
                  </Link>
                </DateWrap>
                <SmallTableSpace>
                  <Link to={`/profile/audit-trail/${id}`}>
                    <h3>Audit</h3>
                  </Link>
                </SmallTableSpace>
              </TableFlex>
            </TableWrap>
            {userInfo && Object.keys(userInfo).length > 0 ? (
              <>
                <UserCard>
                  <div>
                    <div>
                      <p>User Id</p>
                      <h3>{userInfo.bid}</h3>
                    </div>
                    <div>
                      <BasicFlex>
                        <IconCard background="#E0E3EB" color="#122466">
                          <Icon.Call />
                        </IconCard>
                        <div>
                          <GreyText>PHONE</GreyText>
                          <h3>{userInfo.phone}</h3>
                        </div>
                      </BasicFlex>
                    </div>
                    <GridTexts>
                      <p>BVN</p>
                      <h3>{bvn ? bvn : "Not Verified"}</h3>
                    </GridTexts>
                    <GridTexts>
                      <p>Account Number</p>
                      <h3
                        style={{
                          fontSize: "13px",
                        }}
                      >{`${
                        account && account.accountNumber
                          ? account.accountNumber
                          : ""
                      } | ${
                        account && account.bankName ? account.bankName : ""
                      }`}</h3>
                    </GridTexts>
                  </div>
                  <Line />
                  <div>
                    <UserProfileCard>
                      {userInfo.image ? (
                        <AvatarImage src={userInfo.image} alt="Profile" />
                      ) : (
                        <IconCard
                          background="#FEF9E5"
                          color="#3E3E0D"
                          style={{
                            fontWeight: 800,
                          }}
                        >
                          {userInfo.businessName
                            ? userInfo.businessName[0].toUpperCase()
                            : ""}
                        </IconCard>
                      )}
                      <div>
                        <span>User</span>
                        <h3>{`${userInfo.businessName}`}</h3>
                        <p>{userInfo.email}</p>
                      </div>
                    </UserProfileCard>
                    <GridTexts>
                      <p>Created</p>
                      <h3>{moment(userInfo.createdAt).format("LL")}</h3>
                    </GridTexts>
                    <GridTexts>
                      <p>Last Updated KYC</p>
                      <h3>
                        {userInfo.idDoc === 0
                          ? "Not Updated"
                          : moment(userInfo.user_updated_at).format("LL")}
                      </h3>
                    </GridTexts>
                    <GridTexts>
                      <p>Status</p>
                      <h3
                        style={{
                          color: "#3E3E0D",
                        }}
                      >
                        {getKycStatus(userInfo.idDoc)}
                      </h3>
                    </GridTexts>
                    <BalanceCard>
                      <IconCard background="#4DC736" color="#fff">
                        &#8358;
                      </IconCard>
                      <GridTexts>
                        <p>Balance</p>
                        <h3>
                          &#8358;
                          {CommaNumber(userInfo ? userInfo.walletBalance : "")}
                        </h3>
                      </GridTexts>
                    </BalanceCard>
                  </div>
                  <SpaceWrap></SpaceWrap>
                </UserCard>
                <HeaderItems>
                  <DashboardInput
                    wrapWidth={"40%"}
                    showSearch={true}
                    transform="none"
                  >
                    <input
                      placeholder="Search transactions"
                      value={searchedItem}
                      onChange={(e) => {
                        setSearchedItem(e.target.value);
                      }}
                    />
                    <i>
                      <Icon.SearchNormal1
                        size={18}
                        onClick={() => searchItem()}
                      />
                    </i>
                  </DashboardInput>
                  <CsvDownloader
                    datas={exportData}
                    filename={`Transactions - ${moment(date)}`}
                  >
                    <button
                      ref={exportButton}
                      style={{ visibility: "hidden" }}
                    ></button>
                  </CsvDownloader>
                  <FlexedBtn onClick={() => setOpenExport(true)}>
                    <button>
                      <Icon.DocumentDownload />
                      Export
                    </button>
                  </FlexedBtn>
                  <IconBtn
                    background="#4DC736"
                    color="#fff"
                    onClick={() => refreshFilter()}
                  >
                    <Icon.ArrowRotateLeft color="#fff" />
                  </IconBtn>
                </HeaderItems>
                <TableWrap>
                  <TableFlex>
                    <SmallTableSpace>
                      <h3>I.D</h3>
                    </SmallTableSpace>
                    <SmallTableSpace>
                      <h3>Date</h3>
                    </SmallTableSpace>
                    <DateWrap>
                      <h3>Narration</h3>
                    </DateWrap>
                    <DateWrap>
                      <h3>Sender Details</h3>
                    </DateWrap>
                    <DateWrap>
                      <h3>Reference</h3>
                    </DateWrap>
                    <SmallTableSpace>
                      <h3>Amount</h3>
                    </SmallTableSpace>
                    <SmallTableSpace>
                      <p>Status</p>
                    </SmallTableSpace>
                  </TableFlex>
                  <Line />
                  {transactions !== "" ? (
                    transactionsMutable && transactionsMutable.length > 0 ? (
                      <>
                        {transactionsMutable.map((item: any, index: number) => (
                          <TableFlex key={index}>
                            <SmallTableSpace>
                              <h3>{item.transactionID}</h3>
                            </SmallTableSpace>
                            <SmallTableSpace>
                              <p>{moment(item.Date).format("LL")}</p>
                            </SmallTableSpace>
                            <DateWrap>
                              <p>
                                {item.narration
                                  ? item.narration
                                  : "No Narration"}
                              </p>
                            </DateWrap>
                            <DateWrap>
                              <UserProfileCard>
                                <div>
                                  <span
                                    style={{
                                      color: "#161616",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item.fullName ? item.fullName : ""}
                                  </span>
                                  <span
                                    style={{
                                      color: "#3B4CB8",
                                      fontSize: "10px",
                                      margin: "10px 0 0 0",
                                    }}
                                  >
                                    {item.bankName ? item.bankName : ""}
                                  </span>
                                  <h3
                                    style={{
                                      color: "#161616",
                                      fontSize: "10px",
                                      fontWeight: "400",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    {item.accountNumber
                                      ? `${item.accountNumber}`
                                      : ""}
                                  </h3>
                                </div>
                              </UserProfileCard>
                            </DateWrap>
                            <DateWrap>
                              <p>{item.reference}</p>
                            </DateWrap>
                            <SmallTableSpace>
                              <p
                                style={
                                  item.statusType === "Debit" ||
                                  item.serviceType === "Transfer"
                                    ? {
                                        color: "#F42F4B",
                                      }
                                    : {
                                        color: "#4DC736",
                                      }
                                }
                              >
                                {item.statusType === "Debit" ||
                                item.serviceType === "Transfer"
                                  ? "-"
                                  : ""}
                                &#8358;
                                {CommaNumber(item.amount)}
                              </p>
                            </SmallTableSpace>
                            <SmallTableSpace>
                              <StatusCard
                                bg={`${handleBg(item.status)}`}
                                color={`${handleColor(item.status)}`}
                              >
                                {item.status}
                              </StatusCard>
                            </SmallTableSpace>
                          </TableFlex>
                        ))}
                      </>
                    ) : (
                      <EmptyState />
                    )
                  ) : (
                    <Loader />
                  )}
                </TableWrap>
              </>
            ) : (
              <Loader />
            )}
            <PaginationDiv>
              <p>
                Showing <span>{itemsPerPage}</span> of {totalItems} enteries
              </p>
              <div>
                <Pagination
                  count={Math.ceil(totalItems / limitPerPage)}
                  page={page}
                  onChange={handleChange}
                  boundaryCount={2}
                  siblingCount={1}
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      bgcolor: "#F1F1F1",
                      color: "#666666",
                      "&:hover": {
                        bgcolor: "#666666",
                        color: "#ffffff",
                      },
                    },

                    "& .Mui-selected": {
                      bgcolor: "#666666 !important",
                      color: "#ffffff !important",
                      fontSize: "18px",
                      fontWeight: "medium",
                    },
                  }}
                />
              </div>
            </PaginationDiv>
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>
    </>
  );
};

export default UserTransactionComp;
