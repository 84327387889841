import React, { useState } from 'react';
import { MainWrap, AuthFlex, AuthLeft, AuthRight, AuthLogoTop, AuthImage, AuthLogoImage, InputField } from '../../styles/authentication';
import { InputWrap } from '../../styles/authentication';
import { Button } from '../../styles/reusable';
import { useParams, useNavigate } from 'react-router';
import Error from '../mPos/reusable/error';
import axios from 'axios';
import * as Icon from 'react-feather'
import { ClipLoader } from 'react-spinners';

const ChangePwd = () => {

    const navigate = useNavigate();
    const { email } = useParams();

    // States 
    const [password, setPassword] = useState<string>('')
    const [confirmedPassword, setConfirmedPassword] = useState<string>('');
    const [error, setError] = useState<string>('')
    const [onProcess, setOnProcess] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false)

    // Password Check and Validation

    const basicPayload = {
        email,
        password
    }

    const createPassword = (e:any) => {
        e.preventDefault();
        setOnProcess(true);
        if (password === confirmedPassword){
            axios.post(`${process.env.REACT_APP_BASE_URL}/auth/admins/password`, basicPayload).then((res) => {
                setOnProcess(false);
                navigate('/login');
            }).catch((err) => {
                setOnProcess(false);
                setError(err.response.data.message);
                setTimeout(() => {
                    setError('');
                }, 4000)
            })
        }else {
            setOnProcess(false);
            setError('Password does not match!');
            setTimeout(() => {
                setError('')
            }, 4000)
        }
    }

    return(
        <>
            <MainWrap>
                <AuthFlex>
                    <AuthLeft>
                        <h3>
                            Best solutions & <br />
                            value-for your businesses
                        </h3>
                        <AuthImage 
                            src='/images/auth.png'
                            alt='Mongoro'
                        />
                        <AuthLogoImage 
                            src='/mongoro-white.png'
                            alt='Mongoro'
                        />
                    </AuthLeft>
                    <AuthRight onSubmit={(e) => createPassword(e)}>
                        <AuthLogoTop 
                            src='/mongoro.svg'
                            alt='Mongoro'
                        />
                        <div>
                            <h3>Mongoro Administration</h3>
                            <InputWrap>
                                <InputField>
                                    <legend>Choose a password</legend>
                                    <input 
                                        type={showPassword ? 'text': 'password'}
                                        placeholder='password'
                                        autoComplete="off"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    {
                                        showPassword ?
                                            <i>
                                                <Icon.EyeOff 
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            </i>
                                            :
                                            <i>
                                                <Icon.Eye 
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            </i>
                                    }
                                </InputField>
                                <InputField>
                                    <legend>Retype Password</legend>
                                    <input 
                                        type={showPassword ? 'text': 'password'}
                                        placeholder='Retype Password'
                                        autoComplete="off"
                                        onChange={(e) => setConfirmedPassword(e.target.value)}
                                    />
                                    {
                                        showPassword ?
                                            <i>
                                                <Icon.EyeOff 
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            </i>
                                            :
                                            <i>
                                                <Icon.Eye 
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            </i>
                                    }
                                </InputField>
                                <Button
                                    bg='var(--primary-color)'
                                    color='#fff'
                                >
                                    {onProcess ? <ClipLoader color='#fff' /> : 'Save'}
                                </Button>
                            </InputWrap>
                        </div>
                    </AuthRight>
                </AuthFlex>
            </MainWrap>
        </>
    )
}

export default ChangePwd;