import { useEffect, useState } from "react"
import "../../styles/settings/roles.scss"
import roles from "./roles.json"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/types/types"
import { setToggleSettings } from "../../redux/slices/toggleSettingsSlice"
import { setPermissionsPayload } from "../../redux/slices/permissionsPayloadSlice"
import { axiosInstance } from "../../services/AxiosInstance"
export default function Roles() {

    interface adminCategoryState {
        [key: string]: string
    }


    const [adminRoles, setAdminRoles] = useState<adminCategoryState[]>([])
    const toggleSettings = useSelector((state: RootState) => state.toggleSettings)
    const permissionsPayload = useSelector((state: RootState) => state.permissionsPayload)
    const dispatch = useDispatch()

    useEffect(() => {
        getCategories()
    }, [toggleSettings])

    async function getCategories() {
        try {
            await axiosInstance.get("/super/admin/category/all").then((resp) => {
                setAdminRoles(resp.data.data)
            })
        } catch (error) {

        }
    }

    function handleToggleSettings(route:string, property: string, role?: string | any, title?: string | any, ) {
        const newObject: Record<string, boolean | string> = { ...toggleSettings };
        const objectKeys = Object.keys(newObject);

        objectKeys.forEach((key: string) => {
            newObject[key] = false;
        });
        newObject[property] = true;
        newObject[role] = route;
        dispatch(setToggleSettings(newObject));
        dispatch(setPermissionsPayload({ ...permissionsPayload, categoryID: title, toAccess:route }))

    }
    return <div className="roles-container">

      {
        adminRoles.length?(
            <div className="content">
            { adminRoles.map((role, i) => {
                    return <div className="role" key={i}>
                        <h3>{role.category}</h3>
                        <div className="description">
                            {
                                role.description
                            }
                        </div>
                        <div className="role-footer">
                            <div className="user">
                                {role.user}
                            </div>

                            <button
                                onClick={() => {
                                    window.history.pushState(null, "", `?id=${role.cid}`)
                                    handleToggleSettings(role.category, "permissionLevels", "role", `${role.cid}`);
                                }}
                            >See permissions</button>

                        </div>
                    </div>
                })
            }
        </div>
        ):null
      }








    </div>
}
