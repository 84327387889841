import React, { useState, useEffect, useRef } from "react";
import {
  DashboardMainFlex,
  DashboardMainBody,
  MenuPopUp,
  AppMainBody,
  DescHeader,
  FlexedBtn,
  DateWrap,
  MenuSpace,
  DashboardInput,
  TableWrap,
  TableFlex,
  SmallTableSpace,
  TableName,
  Line,
  UserCard,
  IconCard,
  HeaderItems,
  FilterSelect,
  IconBtn,
  UserProfileCard,
  StatusCard,
} from "../../styles/reusable/index";
import SideBarWidget from "../mPos/reusable/sidebar";
import Header from "../mPos/reusable/header";
import * as Icon from "iconsax-react";
import * as FeatherIcon from "react-feather";
import moment from "moment";
import CommaNumber from "comma-number";
import axios from "axios";
import Loader from "../mPos/reusable/loader";
import EmptyState from "../mPos/reusable/emptyState";
import { useNavigate, useParams } from "react-router";
import CsvDownloader from "react-csv-downloader";
import BreadcrumbArea from "../mPos/reusable/breadcrumb";
import { handleBg, handleColor } from "../../utils/colorHandle";
import PaginationComp from "../mPos/reusable/pagination";
import RangeModal from "../mPos/reusable/rangeModal";
import { paginationItemClasses } from "@mui/material";
import { axiosInstance } from "../../services/AxiosInstance";
import "../../styles/subAccounts.scss";
import { nameCase } from "../../utils/modifiers/formatNames";
import {
  Edit,
  MenuOpen,
  ModeRounded,
  MoreVert,
  PlayCircleFilled,
  Refresh,
} from "@mui/icons-material";
import CreateSubAccount from "./modals/createSubaccount";

const SingleAccount = () => {
  const date = new Date();
  const navigate = useNavigate();
  const loggedAdmin = localStorage.getItem("tks") || "{}";
  const exportButton: any = useRef(null);

  // States
  const [searchedItem, setSearchedItem] = useState("");
  const [activeItem, setActiveItem] = useState(-1);
  const [transactions, setTransactions] = useState<any>("");
  const [transactionsMutable, setMutableTransactions] = useState<any>([]);
  let [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState<string | number | any>("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openExport, setOpenExport] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [onProcess, setOnProcess] = useState(false);

  const [subAccount, setSubAccount] = useState<{
    [key: string]: string | number | boolean | any;
  }>({});

  const [addAccount, setAddAccount] = useState(false);
  const [subAccounts, setSubAccounts] = useState();
  const [subAccountMutable, setSubAccountsMutable] = useState();

  const params = useParams();

  useEffect(() => {
    getBusiness();
    getSubAccounts();
  }, []);

  // Call Function
  const getBusiness = async () => {
    await axiosInstance
      .get(`/super/admin/sub/single/${params.id}`)
      .then((res) => {
        setSubAccount(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  const getSubAccounts = async () => {
    await axiosInstance
      .get(`/super/admin/sub/business/${params.id}`)
      .then((res) => {
        console.log(res);
        setSubAccounts(res.data.data);
        setSubAccountsMutable(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  return (
    <div className=" single-subaccount-container">
      <DashboardMainFlex onClick={() => setActiveItem(-1)}>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <BreadcrumbArea navName="Sub-accounts" redirectUrl="/" />
            <DescHeader>
              <h3>Sub-Account</h3>
            </DescHeader>

            <div className="summaries">
              <div className="content">
                <div className="userId">
                  <p>User ID</p>
                  <b>{subAccount.accountID}</b>
                </div>
                <div className="item">
                  <p>PHONE</p>

                  <b>{subAccount.phone || "no info"}</b>
                </div>

                <div className="item">
                  <p>Tier Category</p>

                  <b>Tier 1</b>
                </div>

                <div className="item">
                  <p>Account Number</p>

                  <b>{subAccount.accountNumber || "no info"}</b>
                </div>

                <div className="item amount-section">
                  <center className="currency">₦</center>

                  <div>
                    <p>Amount</p>
                    <b>{subAccount.escrowBalance}</b>
                  </div>
                </div>
              </div>
            </div>

            <div className="profile-section">
              <div className="content">
                <div className="item avatar-section">
                  <div className="avatar">
                    <img src="/images/placeholder.png" alt="" />
                  </div>

                  <div className="name-section">
                    <div className="title">User</div>
                    <b>{subAccount?.subAccountName}o</b>
                    <p>{subAccount.email || "no data"}</p>
                  </div>
                </div>

                <div className="item">
                  <p>Created</p>
                  <b>{new Date(subAccount.createdAt).toDateString()}</b>
                </div>

                <div className="item">
                  <p>Last Updated KYC</p>
                  <b>{new Date(subAccount.updatedAt).toDateString()}</b>
                </div>

                <div className="item">
                  <p>Status</p>
                  <b>Approved</b>
                </div>
                <div className="item">
                  <button onClick={() => setAddAccount(true)}>
                    Add Sub-account
                  </button>
                </div>
              </div>
            </div>

            <div className="search-filter-section">
              <div className="item">
                <select name="" id="">
                  <option value="">Status</option>
                  <option value="">Currency</option>
                </select>
              </div>

              <div className="item search-section">
                <input placeholder="Search Username here" name="" id="" />
              </div>

              <div className="sort">
                <Icon.Sort />
              </div>

              <div className="sort">
                <Refresh className="flip-horizontal" />
              </div>
            </div>

            <div className="accounts-table">
              <table>
                <thead>
                  <tr>
                    <td>I.D</td>
                    <td>Bank Name</td>
                    <td>Account Number</td>
                    <td>Currency</td>
                    <td>Status</td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>12345455</td>
                    <td>
                      <b>Rhema Store - Cloud Equipment</b>
                      <div className="bank-name">Mongoro Bank</div>
                    </td>
                    <td>0171007786</td>
                    <td>NGN</td>
                    <td>
                      <div className="status-view">
                        <div className="view">View</div>
                        <div className="enabled">Enabled</div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>12345455</td>
                    <td>
                      <b>Rhema Store - Cloud Equipment</b>
                      <div className="bank-name">Mongoro Bank</div>
                    </td>
                    <td>0171007786</td>
                    <td>NGN</td>
                    <td>
                      <div className="status-view">
                        <div className="view">View</div>
                        <div className="disabled">Disabled</div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>12345455</td>
                    <td>
                      <b>Rhema Store - Cloud Equipment</b>
                      <div className="bank-name">Mongoro Bank</div>
                    </td>
                    <td>0171007786</td>
                    <td>NGN</td>
                    <td>
                      <div className="status-view">
                        <div className="view">View</div>
                        <div className="pending">Pending</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>

      <div className="modals">
        {addAccount && (
          <CreateSubAccount
            businessId={subAccount?.parentID}
            bussinessName={subAccount?.subAccountName}
            closeFunc={() => setAddAccount(false)}
          />
        )}
      </div>
    </div>
  );
};

export default SingleAccount;
