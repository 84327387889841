import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSelf } from "../../services/getSelf";

export interface adminVariables {
  Access: number[];
  supers: { [key: string]: string };
  response: { [key: string]: string };
}

const initialState: adminVariables = {
  Access: [],
  supers: {},
  response: {},
};

export const getAdmin = createAsyncThunk("loggedAdmin/getAdmin", async () => {
  const { response, error } = await getSelf(); 
  return response?.data;
});

const adminSlice = createSlice({
  name: "loggedAdmin",
  initialState,
  reducers: {
    setAdmin: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdmin.fulfilled, (state, action: PayloadAction<any>) => {
      state.Access = action.payload?.Accesss;
      state.supers = action.payload?.supers;
      state.response = action.payload;
    });
  },
});

export const { setAdmin } = adminSlice.actions;
export default adminSlice.reducer;
