import React, { useState, useEffect } from "react";
import {
  ModalWrap,
  ModalChild,
  ModalHeader,
  InputField,
  UnderlayText,
  UserProfileCard,
  IconCard,
} from "../../styles/reusable/index";
import * as FeatherIcon from "react-feather";
import { Button } from "../../styles/reusable";
import { AvatarImage } from "../mPos/reusable/style";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router";
import { CustomStyles } from "../../utils/selectStyle";
import { ClipLoader } from "react-spinners";
import Alert from "../mPos/reusable/alert";

interface PropArgs {
  closeFunc: any;
  index: string | number;
}

const BlockModal = ({ closeFunc, index }: PropArgs) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [onProcess, setOnProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const loggedAdmin = localStorage.getItem("tks") || "{}";

  const body = {
    headers: {
      "Content-Type": "application/json",
      "x-token": `${loggedAdmin}`,
    },
  };

  const basicPayload = {
    type: `${index}`,
    email,
  };

  // Call Function
  const validateID = (e: any) => {
    e.preventDefault();
    setOnProcess(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/panel/blocking`,
        basicPayload,
        body
      )
      .then((res) => {
        setOnProcess(false);
        setSuccess(true);
      })
      .catch((err) => {
        setOnProcess(false);
        setError(err.response.data.message);
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  const getText = (index: string | number) => {
    switch (index) {
      case 1:
        return "Blocking this User will deny him/her Access to Transfers";
      case 2:
        return "Blocking this User will deny him/her Access to the App";
      case 3:
        return "Blocking this User will deny him/her Access to P2P Transfers";
      default:
        break;
    }
  };

  const getHeaderText = () => {
    switch (index) {
      case 1:
        return "Transfers";
      case 2:
        return "App Access";
      case 3:
        return "P2P Transfers";
      default:
        break;
    }
  };

  return (
    <>
      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            closeFunc();
          }}
          img="/icons/success.png"
          message="Modified Successfully"
          miniMessage="You have successfully blocked this user"
        />
      ) : null}
      <ModalWrap style={success ? { visibility: "hidden" } : {}}>
        <ModalChild>
          <ModalHeader>
            <h3>{`Blocking Activities (${getHeaderText()})`}</h3>
            <i>
              <FeatherIcon.X onClick={() => closeFunc()} />
            </i>
          </ModalHeader>
          <form onSubmit={(e) => validateID(e)}>
            <UnderlayText>{getText(index)}</UnderlayText>
            <InputField>
              <legend>Email</legend>
              <input
                placeholder={"Email Address"}
                type="text"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
            </InputField>
            {error ? (
              <p
                style={{
                  color: "#c82b32",
                  fontSize: "13px",
                }}
              >
                {error}
              </p>
            ) : null}
            <Button
              width="100%"
              bg="#ADB7BE"
              color="#fff"
              type="submit"
              disabled={onProcess}
              style={
                onProcess
                  ? {
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              {onProcess ? <ClipLoader color="#fff" /> : "Block"}
            </Button>
          </form>
        </ModalChild>
      </ModalWrap>
    </>
  );
};

export default BlockModal;
