import React, { useState, useEffect, useRef } from "react";
import {
  DashboardMainFlex,
  DashboardMainBody,
  AppMainBody,
  DescHeader,
  FlexedBtn,
  DateWrap,
  Role,
  MenuSpace,
  DashboardInput,
  TableWrap,
  TableFlex,
  SmallTableSpace,
  TableName,
  Line,
  UserCard,
  IconCard,
  HeaderItems,
  FilterSelect,
  IconBtn,
  UserProfileCard,
  StatusCard,
} from "../../styles/reusable/index";
import SideBarWidget from "../mPos/reusable/sidebar";
import Header from "../mPos/reusable/header";
import * as Icon from "iconsax-react";
import * as FeatherIcon from "react-feather";
import axios from "axios";
import Loader from "../mPos/reusable/loader";
import EmptyState from "../mPos/reusable/emptyState";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AvatarImage } from "../mPos/reusable/style";
import BreadcrumbArea from "../mPos/reusable/breadcrumb";
import CsvDownloader from "react-csv-downloader";
import PaginationComp from "../mPos/reusable/pagination";
import moment from "moment";
import RangeModal from "../mPos/reusable/rangeModal";
import { axiosInstance } from "../../services/AxiosInstance";
import { MoreVert } from "@mui/icons-material";

const UsersMain = () => {
  const date = new Date();
  const navigate = useNavigate();
  const exportButton: any = useRef(null);

  // States
  const [users, setUsers] = useState<any>("");
  const [usersMutable, setMutableUsers] = useState<any>([]);
  const [searchedItem, setSearchedItem] = useState("");
  let [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState<string | number | any>("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openExport, setOpenExport] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [exportProcess, setExportProcess] = useState(false);

  // Export Range

  const loggedAdmin = localStorage.getItem("tks") || "{}";

  const body = {
    headers: {
      "Content-Type": "application/json",
      "x-token": `${loggedAdmin}`,
    },
  };

  const getRangeData = (e: any) => {
    e.preventDefault();
    if (startDate && endDate) {
      setExportProcess(true);
      const loggedAdmin = localStorage.getItem("tks") || "{}";

      const body = {
        headers: {
          "Content-Type": "application/json",
          "x-token": `${loggedAdmin}`,
        },
      };

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/range/users/${new Date(
            startDate
          ).getTime()}/${new Date(endDate).getTime()}`,
          body
        )
        .then((res) => {
          setExportProcess(false);
          if (res.data.statement.length > 0) {
            setExportData(res.data.statement);
          }
        })
        .catch((err) => {
          setExportProcess(false);
          if (err?.response?.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  // Search Endpoint
  const searchItem = () => {
    if (searchedItem) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/search/users?limit=20&page=${page}&key=${searchedItem}`,
          body
        )
        .then((res) => {
          setUsers(res.data.data);
          setMutableUsers(res.data.data);
          setTotalData(res.data.data.length);
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click();
      setOpenExport(false);
    }
  }, [exportData]);

  // Call Function
  const getUsers = () => {
    axiosInstance
      .get(`/business/all?limit=20&page=${page}`, body)
      .then((res) => {
        setUsers(res.data.data.data);
        setMutableUsers(res.data.data.data);
        setTotalData(res.data.data.total);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };
  // Get Users
  useEffect(() => {
    getUsers();
  }, []);

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page);
      getUsers();
    }
  };

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page);
      getUsers();
    }
  };

  // Refresh Filters
  const refreshFilter = () => {
    setSearchedItem("");
    getUsers();
  };

  // Search Algorithm
  const handleSearch: any = (e: any) => {
    let new_item: any = [];
    if (users !== "" && users.length !== 0) {
      for (let i = 0; i < users.length; i++) {
        if (
          (users[i].firstName &&
            users[i].firstName
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (users[i].surname &&
            users[i].surname
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (users[i].phone && users[i].phone.includes(e.target.value))
        ) {
          new_item.push(users[i]);
        } else if (e.target.value === "") {
          setMutableUsers(users);
        }
      }
      setMutableUsers(new_item);
    }
  };

  return (
    <>
      {openExport ? (
        <RangeModal
          closeFunc={() => setOpenExport(false)}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          action={getRangeData}
          onProcess={exportProcess}
        />
      ) : null}
      <DashboardMainFlex>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <BreadcrumbArea navName="Users" redirectUrl="/" />
            <DescHeader>
              <h3>Users ({totalData})</h3>
            </DescHeader>
            <HeaderItems>
              <DashboardInput
                wrapWidth={"40%"}
                showSearch={true}
                transform="none"
              >
                <input
                  placeholder="Search user name here"
                  value={searchedItem}
                  onChange={(e) => {
                    setSearchedItem(e.target.value);
                  }}
                />
                <i>
                  <Icon.SearchNormal1 size={18} onClick={() => searchItem()} />
                </i>
              </DashboardInput>
              <CsvDownloader
                datas={exportData}
                filename={`Users - ${moment(date)}`}
              >
                <button
                  ref={exportButton}
                  style={{ visibility: "hidden" }}
                ></button>
              </CsvDownloader>
              <FlexedBtn onClick={() => setOpenExport(true)}>
                <button>
                  <Icon.DocumentDownload />
                  Export
                </button>
              </FlexedBtn>
              <IconBtn
                background="#4DC736"
                color="#fff"
                onClick={() => refreshFilter()}
              >
                <Icon.ArrowRotateLeft color="#fff" />
              </IconBtn>
            </HeaderItems>

            <TableWrap>
              {users !== "" ? (
                usersMutable && usersMutable.length > 0 ? (
                  <>
                    {usersMutable.map((item: any, index: number) => (
                      <Link key={index} to={`/profile/${item.bid}`}>
                        <TableFlex key={index}>
                          <Role style={{ width: "22%" }}>
                            {item.updatedAt ? (
                              <h3>
                                {new Date(item.updatedAt).toDateString()},{" "}
                                {new Date(item.updatedAt).toLocaleTimeString()}
                              </h3>
                            ) : (
                              ""
                            )}{" "}
                            <h3></h3>
                          </Role>

                          <TableName>
                            <UserProfileCard>
                              {item.image ? (
                                <AvatarImage src={item.image} alt="Profile" />
                              ) : (
                                <IconCard
                                  background="#FEF9E5"
                                  color="#3E3E0D"
                                  style={{
                                    fontWeight: 800,
                                  }}
                                >
                                  {item.firstName &&
                                    item.firstName[0].toUpperCase()}
                                </IconCard>
                              )}
                              <div>
                                <h3>
                                  {`${
                                    item.businessName ? item.businessName : ""
                                  }
                                                                        ${
                                                                          item.surname
                                                                            ? item.surname
                                                                            : ""
                                                                        }`}
                                  {!item.businessName && !item.businessName
                                    ? "No Name"
                                    : null}
                                </h3>
                                <p>{item.email && item.email}</p>
                              </div>
                            </UserProfileCard>
                          </TableName>

                          <Role style={{ width: "25%" }}>
                            <p>Location</p>
                            <h3>
                              {item.businessAddress
                                ? item.businessAddress
                                : "No Address"}
                            </h3>
                          </Role>
                          <DateWrap>
                            <p>Phone Number</p>
                            <h3>{item.phone}</h3>
                          </DateWrap>
                          <SmallTableSpace>
                            <StatusCard bg="#DBFFDA" color="#68E365">
                              {item.blocked ? "Disabled" : "Enabled"}
                            </StatusCard>
                          </SmallTableSpace>
                          <Icon.Edit2 color="#32D16D" size={18} />
                        </TableFlex>
                      </Link>
                    ))}
                    <PaginationComp
                      page={page}
                      setPage={setPage}
                      limit={20}
                      total={totalData}
                      incrementAction={incrementAction}
                      decrementAction={decrementAction}
                    />
                  </>
                ) : (
                  <EmptyState />
                )
              ) : (
                <Loader />
              )}
            </TableWrap>
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>

      {/*  NEW USER DESIGN
            <div className="header">
                <div className="flex-between">
                    <div className="add-user-btn">
                        <FeatherIcon.UserPlus /> Add User
                    </div>
                    <DashboardInput
                        wrapWidth={'50%'}
                        showSearch={true}
                        transform='none'
                    >
                        <input
                            placeholder='Search Username here'
                            value={searchedItem}
                            onChange={(e) => {
                                setSearchedItem(e.target.value)
                            }}
                        />
                        <i>
                            <Icon.SearchNormal1
                                size={18}
                                onClick={() => searchItem()}
                            />
                        </i>
                    </DashboardInput>

                    <div className="input-section">
                        <select name="" id="">
                            <option value="">Role</option>
                        </select>
                    </div>

                    <div className="filter-section">
                        Filter
                        <Icon.Sort />
                    </div>

                    <CsvDownloader
                        datas={exportData}
                        filename={`Transactions - ${moment(date)}`}

                    >
                        <button ref={exportButton} style={{ visibility: 'hidden' }}>

                        </button>
                    </CsvDownloader>
                    <FlexedBtn
                        onClick={() => setOpenExport(true)}
                    >
                        <button>
                            <Icon.DocumentDownload />
                            Download
                        </button>
                    </FlexedBtn>
                    <IconBtn
                        style={{ width: "70px" }}
                        background='#4DC736'
                        color='#fff'
                        onClick={() => refreshFilter()}
                    >
                        <Icon.ArrowRotateLeft color='#fff' />
                    </IconBtn>

                </div>
            </div>

            <div className="accounts-table-section">
                <table>
                    <thead>
                        <tr>
                            <td>Date & Time</td>
                            <td>User</td>
                            <td>Address</td>
                            <td>Phone Number:</td>
                            <td>Gender</td>
                            <td>Status</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>

                            <td>June 1, 2020, 08:22 AM</td>
                            <td>
                                <div className="profile">
                                    <div className='user-image'>

                                    </div>
                                    <div>
                                        <b>Rhema Store, Kado</b>
                                        <p>Adeife@gmail.com</p>
                                    </div>
                                </div>
                            </td>
                            <td>No. 34 Contonou Crescent, Wuse</td>
                            <td>0815456664343</td>
                            <td>Female</td>
                            <td> <b className="disabled">Disabled</b>
                            </td>
                            <td  >
                                <div className="flex-members">

                                    <FeatherIcon.Edit3 color='green' fill='green' size={15} />
                                    <MoreVert style={{ "width": "15px" }} />
                                </div>

                            </td>
                        </tr>

                    </tbody>


                </table>
            </div> */}
    </>
  );
};

export default UsersMain;
