import React, { useState, useEffect } from 'react';
import { BigCardsGrid, GraphCard, TopRight } from '../../../styles/dashboard/graphStyle';
import { InputField, GridPaddedSpace, FlexedBtn } from '../../../styles/reusable/index';
import axios from 'axios';
import { Button } from '../../../styles/reusable';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Alert from '../../mPos/reusable/alert';
import { getGatewayDetails } from '../../../services/accounts/GetGatewaysProviderModel';

interface PropsArgs {
    mode: string;
    active: string;
    setActive: any;
    setMode: any;
    model: any;
    activeVendor: string;
    vendors: Array<[]>
}

const Collection = ({mode, active, setActive, setMode, model, vendors, activeVendor} : PropsArgs) => {
 

    const [providerModels, setProviderModels] = useState<{ [key: string]: any }[]>([])
    const [thisModel, setThisModel] = useState<{ [key: string]: string | any }>({});



    async function getModels() {
        const { response } = await getGatewayDetails(4)
        setProviderModels(response?.data)
    }


    useEffect(() => {
        getModels()

    }, [])

    useEffect(() => {
        filterModel(providerModels?.length && providerModels[0]?.vendorName)
    }, [setProviderModels, providerModels])



    function filterModel(vendor: string) {
        const selectedVendorsModel: any = providerModels?.filter(
            ({ vendorName }) => {
                return vendorName === vendor;
            }
        );


        if (selectedVendorsModel?.length > 0) {
            const filtereedModel = selectedVendorsModel[0].MBusinessModel;
 

            if (filtereedModel) {
                const filteredByType = filtereedModel.filter(({ type }: any) => {
                    return type === 4;
                });

                setThisModel(filteredByType[0]);
            }

            // setThisModel() 

        } else {
            // console.log("No model found for the selected vendor.");
        }
    }


 


    return(
        <>
            <GraphCard margin='0 0 2rem 0'>
               
                <BigCardsGrid>
                    <GridPaddedSpace>
                        <h3>Collection</h3>
                        <div>
                            <InputField>
                                <legend>Vendor name</legend>
                                <select
                                    onChange={(e) => {
                                        filterModel(e.target.value)

                                    }}
                                >
                                    {
                                        providerModels?.length > 0 ?
                                            providerModels.map((item: any, index) => (
                                                <option key={index}>
                                                    {item.vendorName}
                                                </option>
                                            ))
                                            :
                                            <option>No Vendor</option>
                                    }
                                </select>
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (Bank Transfer)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    readOnly
                                    value={thisModel?.feeTransfer || 0.00}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Cap </legend>
                                <input 
                                    placeholder={'0.000'}
                                    readOnly
                                    value={thisModel?.cap || 0.00}
                                />
                            </InputField>
                        </div>

                        <div>
                            <InputField>
                                <legend>Fee (USSD)</legend>
                                <input 
                                    placeholder={'0.000'}
                                    readOnly
                                    value={thisModel?.feeUssd || 0.00}
                                />
                            </InputField>
                        </div>
                    </GridPaddedSpace>
                    <GridPaddedSpace>
                        <div>
                        <h3>Mongoro</h3>
                        <div>
                            <InputField>
                                <legend>Transfer Fee (Bank)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    readOnly
                                    value={thisModel?.mongoroFeeTransfer || 0.00}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Cap </legend>
                                <input 
                                    placeholder={'0.00'}
                                    readOnly
                                    value={thisModel?.mongoroCap || 0.00}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (USSD)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    readOnly
                                    value={thisModel?.mongoroFeeUssd || 0.00}
                                />
                            </InputField>
                        </div>
                    </div>
                    </GridPaddedSpace>
                </BigCardsGrid>
                <FlexedBtn
                    style={{
                        margin: '0rem 0 1rem 0',
                        gap: '5px'
                    }}
                >
                    <Link to={`/discounted-users/collection`}>
                        <button
                            style={{
                                border: '1px solid #3E3E0D',
                                color: '#3E3E0D',
                                background: 'transparent'
                            }}
                        >
                            View discounted users
                        </button>
                    </Link>
                </FlexedBtn>
            </GraphCard>
        
        </>
    )
}

export default Collection;