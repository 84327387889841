import React, { useState, useEffect, useRef } from "react";
import {
  DashboardMainFlex,
  DashboardMainBody,
  MenuPopUp,
  AppMainBody,
  DescHeader,
  FlexedBtn,
  DateWrap,
  MenuSpace,
  DashboardInput,
  TableWrap,
  TableFlex,
  SmallTableSpace,
  TableName,
  Line,
  UserCard,
  IconCard,
  HeaderItems,
  FilterSelect,
  IconBtn,
  UserProfileCard,
  StatusCard,
} from "../../styles/reusable/index";
import SideBarWidget from "../mPos/reusable/sidebar";
import Header from "../mPos/reusable/header";
import * as Icon from "iconsax-react";
import * as FeatherIcon from "react-feather";
import moment from "moment";
import CommaNumber from "comma-number";
import axios from "axios";
import Loader from "../mPos/reusable/loader";
import EmptyState from "../mPos/reusable/emptyState";
import { useNavigate } from "react-router";
import CsvDownloader from "react-csv-downloader";
import BreadcrumbArea from "../mPos/reusable/breadcrumb";
import { handleBg, handleColor } from "../../utils/colorHandle";
import PaginationComp from "../mPos/reusable/pagination";
import RangeModal from "../mPos/reusable/rangeModal";
import { paginationItemClasses } from "@mui/material";
import { axiosInstance } from "../../services/AxiosInstance";
import "../../styles/subAccounts.scss";
import { nameCase } from "../../utils/modifiers/formatNames";
import {
  Edit,
  MenuOpen,
  ModeRounded,
  MoreVert,
  PlayCircleFilled,
} from "@mui/icons-material";

const SubAccounts = () => {
  const date = new Date();
  const navigate = useNavigate();
  const loggedAdmin = localStorage.getItem("tks") || "{}";
  const exportButton: any = useRef(null);

  interface Account {
    id: number;
    name: string;
    status: string;
    accountName: string;
    [key: string]: string | number;

    // Add other properties here as needed
  }

  // States
  const [searchedItem, setSearchedItem] = useState("");
  const [activeItem, setActiveItem] = useState(-1);
  const [transactions, setTransactions] = useState<any>("");
  const [transactionsMutable, setMutableTransactions] = useState<any>([]);
  const [subAccounts, setSubAccounts] = useState<Account[]>([]);
  const [subAccountsMutable, setSubAccountsMutable] = useState<Account[]>([]);
  let [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState<string | number | any>("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openExport, setOpenExport] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [onProcess, setOnProcess] = useState(false);

  const [initSelected] = useState({
    all: false,
    active: false,
    disabled: false,
    pending: false,
  });
  const [selected, setSelected] = useState({
    ...initSelected,
    all: true,
  });

  function filterAccountsByStatus(query: string): void {
    let result: Account[] = [];

    if (query === "all") {
      result = [...subAccountsMutable];
    } else {
      result = subAccountsMutable.filter((account) => {
        return account.status.toLowerCase() === query.toLowerCase();
      });
    }

    setSubAccounts(result);
  }

  function filterAccountsBySearch(query: string): void {
    const lowercaseQuery = query.toLowerCase(); // Convert the query to lowercase once

    const result = !query
      ? [...subAccountsMutable]
      : subAccountsMutable.filter(
          (account: { [key: string]: string | number | any }) =>
            (account.subAccountName &&
              account.subAccountName.toLowerCase().includes(lowercaseQuery)) ||
            (account.email &&
              account.email.toLowerCase().includes(lowercaseQuery)) ||
            (account.status &&
              account.status.toLowerCase().includes(lowercaseQuery)) ||
            (account.status &&
              account.accountID.toLowerCase().includes(lowercaseQuery))
        );

    setSubAccounts(result);
  }

  // function filterAccountsBySearch(query: string): void {
  //     let result: Account[] = [];
  //     console.log(query)

  //     if (!query) result = [...subAccountsMutable];

  //     for (let i = 0; i < subAccountsMutable.length; i++) {
  //         const account = subAccountsMutable[i];
  //       if (account.accountName.includes(query.toLocaleLowerCase())){
  //         result.push(account)
  //       }
  //     }

  //     setSubAccounts(result);
  // }

  // Export Range

  const body = {
    headers: {
      "Content-Type": "application/json",
      "x-token": `${loggedAdmin}`,
    },
  };

  const getRangeData = (e: any) => {
    e.preventDefault();
    if (startDate && endDate) {
      setOnProcess(true);

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/range/transaction/${new Date(
            startDate
          ).getTime()}/${new Date(endDate).getTime()}`,
          body
        )
        .then((res) => {
          setOnProcess(false);
          if (res.data.statement.length > 0) {
            setExportData(res.data.statement);
          }
        })
        .catch((err) => {
          setOnProcess(false);
          if (err?.response?.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  // Search Endpoint
  const searchItem = () => {
    if (searchedItem) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/search/transactions?limit=20&page=${page}&key=${searchedItem}`,
          body
        )
        .then((res) => {
          setTransactions(res.data.data);
          setMutableTransactions(res.data.data);
          setTotalData(res.data.data.length);
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click();
      setOpenExport(false);
    }
  }, [exportData]);

  // Call Function
  const getSubAccounts = async () => {
    await axiosInstance
      .get(`/super/admin/sub/all?limit=20&page=${page}`)
      .then((res) => {
        setSubAccounts(res.data.data);
        setSubAccountsMutable(res.data.data);
        // setTransactions(res.data.data.data);
        // setMutableTransactions(res.data.data.data);
        // setTotalData(res.data.data.total)
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  // Get Transactions
  useEffect(() => {
    getSubAccounts();
  }, []);
  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page);
      getSubAccounts();
    }
  };

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page);
      getSubAccounts();
    }
  };

  // Refresh Filters
  const refreshFilter = () => {
    setSearchedItem("");
    getSubAccounts();
  };

  return (
    <div className="sub-accounts-container">
      {openExport ? (
        <RangeModal
          closeFunc={() => setOpenExport(false)}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          action={getRangeData}
          onProcess={onProcess}
        />
      ) : null}

      <DashboardMainFlex onClick={() => setActiveItem(-1)}>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <BreadcrumbArea navName="Sub-accounts" redirectUrl="/" />
            <DescHeader>
              <h3>Sub-Account</h3>
            </DescHeader>

            <div className="filter-sort-area">
              <ul>
                <li
                  className={selected.all ? "active-link" : ""}
                  onClick={() => {
                    filterAccountsByStatus("all");
                    setSelected({ ...initSelected, all: true });
                  }}
                >
                  All
                </li>
                <li
                  className={selected.active ? "active-link" : ""}
                  onClick={() => {
                    filterAccountsByStatus("active");
                    setSelected({ ...initSelected, active: true });
                  }}
                >
                  Active Accounts
                </li>
                <li
                  className={selected.disabled ? "active-link" : ""}
                  onClick={() => {
                    filterAccountsByStatus("disabled");
                    setSelected({ ...initSelected, disabled: true });
                  }}
                >
                  Disabled
                </li>
                <li
                  className={selected.pending ? "active-link" : ""}
                  onClick={() => {
                    // setSubAccounts(filterAccountsByStatus("pending"))
                    filterAccountsByStatus("pending");
                    setSelected({ ...initSelected, pending: true });
                  }}
                >
                  Pending
                </li>
              </ul>

              <div className="sort">
                Sort by:{" "}
                <select name="" id="">
                  <option value="">Newest to Oldest</option>
                  <option value="">Oldest to Newest</option>
                </select>
              </div>
            </div>

            {/* <HeaderItems > */}

            <div className="header">
              <div className="flex-between">
                <DashboardInput
                  wrapWidth={"50%"}
                  showSearch={true}
                  transform="none"
                >
                  <input
                    placeholder="Search Username here"
                    value={searchedItem}
                    onChange={(e) => {
                      // console.log(e.target.value)
                      filterAccountsBySearch(e.target.value);
                      setSearchedItem(e.target.value);
                    }}
                  />
                  <i>
                    <Icon.SearchNormal1
                      size={18}
                      onClick={() => searchItem()}
                    />
                  </i>
                </DashboardInput>

                <CsvDownloader
                  datas={exportData}
                  filename={`Transactions - ${moment(date)}`}
                >
                  <button
                    ref={exportButton}
                    style={{ visibility: "hidden" }}
                  ></button>
                </CsvDownloader>
                <FlexedBtn
                  onClick={() => {
                    // setOpenExport(true)
                  }}
                >
                  <button>
                    <Icon.DocumentDownload />
                    Export
                  </button>
                </FlexedBtn>
                <IconBtn
                  style={{ width: "70px" }}
                  background="#4DC736"
                  color="#fff"
                  onClick={() => refreshFilter()}
                >
                  <Icon.ArrowRotateLeft color="#fff" />
                </IconBtn>
              </div>
            </div>

            {/* </HeaderItems> */}

            {subAccounts.length ? (
              <div className="accounts-table-section">
                <table>
                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>Business Name</td>
                      <td>Number of Sub-Accounts</td>
                      <td>Date created</td>
                      <td>Status</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {subAccounts.length &&
                      subAccounts.map((account: any) => {
                        return (
                          <tr
                            onClick={() =>
                              navigate(`/subaccounts/single/${account.said}`)
                            }
                            key={account.said}
                          >
                            {/* <td><input type="checkbox" /></td> */}

                            <td>{account.accountID}</td>
                            <td>
                              <div className="profile">
                                <div className="user-image"></div>
                                <div>
                                  <b>{account.subAccountName}</b>
                                  <p>{account.email || "email unavailable"}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <center>2</center>
                            </td>
                            <td>
                              {" "}
                              {new Date(account.createdAt).toDateString()},{" "}
                              {new Date(account.createdAt).toLocaleTimeString()}
                            </td>
                            <td>
                              <b
                                className={
                                  account.status.toLowerCase() === "active"
                                    ? "active-account"
                                    : account.status.toLowerCase() ===
                                      "disabled"
                                    ? "disabled"
                                    : "pending"
                                }
                              >
                                {" "}
                                <li>{account.status}</li>
                              </b>
                            </td>
                            <td>
                              <div className="flex-members">
                                View
                                {/* <MoreVert style={{ "width": "15px" }} /> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyState />
            )}

            {subAccounts.length ? (
              <div className="table-footer">
                <div className="showing">showing 10 of 160 data</div>
                <div className="pages">
                  <FeatherIcon.ChevronLeft size={16} />
                  <span>1</span>
                  <span className="active-page">2</span>
                  <span>3</span>
                  <span>...</span>
                  <span>14</span>
                  <span>15</span>
                  <FeatherIcon.ChevronRight size={16} />
                </div>
              </div>
            ) : null}
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>
    </div>
  );
};

export default SubAccounts;
