import React, { useState, useEffect } from "react";
import {
  ModalWrap,
  ModalChild,
  ModalHeader,
  InputField,
  UnderlayText,
  UserProfileCard,
  IconCard,
} from "../../../styles/reusable/index";
import * as FeatherIcon from "react-feather";
import { Button } from "../../../styles/reusable";
import { AvatarImage } from "../../mPos/reusable/style";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router";
import { CustomStyles } from "../../../utils/selectStyle";
import { ClipLoader } from "react-spinners";
import Alert from "../../mPos/reusable/alert";

interface PropArgs {
  closeFunc: any;
  userId: string;
  userPhone: string;
  userEmail: string;
  active: string;
}

const UpdateIdModal = ({
  closeFunc,
  userId,
  userEmail,
  userPhone,
  active,
}: PropArgs) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [id, setId] = useState("");
  const [onProcess, setOnProcess] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setEmail(userEmail);
    setPhoneNumber(userPhone);
    setId(userId);
  }, [userId, userPhone, userEmail]);

  const loggedAdmin = localStorage.getItem("tks") || "{}";

  const body = {
    headers: {
      "Content-Type": "application/json",
      "x-token": `${loggedAdmin}`,
    },
  };

  const basicPayload = {
    email,
    value: phoneNumber,
  };

  // Call Function
  const updateUser = (e: any) => {
    e.preventDefault();
    setOnProcess(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/panel/lookup`,
        basicPayload,
        body
      )
      .then((res) => {
        setOnProcess(false);
        setSuccess(true);
      })
      .catch((err) => {
        setOnProcess(false);
        setError(err.response.data.message);
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  return (
    <>
      <ModalWrap>
        <ModalChild>
          <ModalHeader>
            <h3>Update Details</h3>
            <i>
              <FeatherIcon.X onClick={() => closeFunc()} />
            </i>
          </ModalHeader>
          <form onSubmit={(e) => updateUser(e)}>
            <InputField>
              <legend>Email</legend>
              <input
                placeholder="Email"
                type="email"
                onChange={(e) => setId(e.target.value)}
                value={email}
                required
              />
            </InputField>
            <InputField>
              <legend>Phone Number</legend>
              <input
                placeholder="number"
                type="text"
                onChange={(e) => setId(e.target.value)}
                value={phoneNumber}
                required
              />
            </InputField>
            <InputField>
              <legend>{active === "1" ? "BVN" : "ID Number"}</legend>
              <input
                placeholder={active === "1" ? "BVN" : "ID Number"}
                type="text"
                onChange={(e) => setId(e.target.value)}
                required
                value={userId}
              />
            </InputField>
            {error ? (
              <p
                style={{
                  color: "#c82b32",
                  fontSize: "13px",
                }}
              >
                {error}
              </p>
            ) : null}
            <Button
              width="100%"
              bg="#ADB7BE"
              color="#fff"
              type="submit"
              disabled={onProcess}
              style={
                onProcess
                  ? {
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              {onProcess ? <ClipLoader color="#fff" /> : "Update"}
            </Button>
          </form>
        </ModalChild>
      </ModalWrap>
    </>
  );
};

export default UpdateIdModal;
