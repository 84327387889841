import React, { useState, useEffect } from "react";
import {
  DashboardMainFlex,
  DashboardMainBody,
  AppMainBody,
  DescHeader,
  InputField,
  FlexedBtn,
  UserCard,
  UserProfileCard,
  GridTexts,
  IconCard,
} from "../../styles/reusable/index";
import SideBarWidget from "../mPos/reusable/sidebar";
import Header from "../mPos/reusable/header";
import BreadcrumbArea from "../mPos/reusable/breadcrumb";
import axios from "axios";
import { useNavigate } from "react-router";
import CreateProvider from "./createProvider";
import Alert from "../mPos/reusable/alert";
import { ClipLoader } from "react-spinners";
import SwitchProvider from "./serviceProviders/SwitchProvider";
import { axiosInstance } from "../../services/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/types/types";
import { getAdmin } from "../../redux/slices/loggedUser";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../hooks/customRedux";

const Providers = () => {
  const navigate = useNavigate();
  const loggedAdmin = localStorage.getItem("tks") || "{}";
  const [fetched, setFetched] = useState(false);
  const [providers, setProviders] = useState<any>([]);
  const [active, setActive] = useState("");
  const [activeIndex, setActiveIndex] = useState<string | number>(1);
  const [id, setId] = useState("");
  const [showIdModal, setShowIdModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [userInformation, setUserInformation] = useState<any>({});
  const [onProcess, setOnProcess] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

  useEffect(() => {
    getServiceProviders();
  }, []);

  const { supers: admin } = useSelector(
    (state: RootState) => state.loggedAdmin
  );
  // console.log(admin)

  // Call Function
  function getServiceProviders() {
    axiosInstance
      .get(`/super/admin/business/service/providers/`)
      .then((res) => {
        setProviders(res.data.data);
        if (res.data.data.length > 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].active) {
              setActive(res.data.data[i].vendorName);
            }
          }
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }

  useEffect(() => {
    if (providers && providers.length > 0) {
      setId(providers.find((p: any) => p.vendorName === active).prid);
    }
  }, [active]);

  const setActiveProvider = () => {
    setOnProcess(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/super/admin/business/providers/active`,
        { prid: id },
        {
          headers: {
            "x-token": `${loggedAdmin}`,
          },
        }
      )
      .then((res) => {
        setSuccess(true);
        setOnProcess(false);
      })
      .catch((err) => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };

  // Get Transactions

  return (
    <>
      <DashboardMainFlex>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <BreadcrumbArea
              navName="Service Providers"
              redirectUrl="/account"
            />
            {/* <DescHeader>
                            <h3>Services Providers</h3>
                        </DescHeader> */}

            <SwitchProvider />

            {/* <InputField
                            style={{
                                width: '15rem'
                            }}
                        >
                            <legend>Select Provider</legend>
                            <select
                                required
                                placeholder={'Select option'}
                                value={active}
                                onChange={(e) => {
                                    setFetched(false)
                                    setActive(e.target.value);
                                }}
                            >
                                {
                                    providers.length > 0 ?
                                        providers.map((item:any, index:number) => (
                                            <option key={index}>{item.vendorName}</option>
                                    ))

                                    : null
                                }
                            </select>
                        </InputField>
                        <FlexedBtn
                            style={{
                                margin: '2rem 0 0 0',
                                gap: '5px'
                            }}
                        >
                            <button
                                onClick={() => setActiveProvider()}
                            >
                                {onProcess ? <ClipLoader color='#fff' /> : 'Save'}
                            </button>
                            <button
                                onClick={() => setShowIdModal(true)}
                                style={{
                                    color: '#3E3E0D',
                                    background: 'transparent',
                                    border: '1px solid #3E3E0D'
                                }}

                            >
                                Create Provider
                            </button>
                        </FlexedBtn>

 */}
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>

      {showIdModal ? (
        <CreateProvider
          closeFunc={() => setShowIdModal(false)}
          closeModal={() => setShowIdModal(false)}
        />
      ) : null}
      {success ? (
        <Alert
          closeFunc={() => setSuccess(false)}
          img="/icons/success.png"
          message="Modified Successfully"
          miniMessage="You have successfully modified the active provider"
        />
      ) : null}
      {error ? (
        <Alert
          closeFunc={() => setError("")}
          img="/icons/error.png"
          message="An error occured!"
          miniMessage={error}
        />
      ) : null}
    </>
  );
};

export default Providers;

const lookupArray = [
  {
    name: "Block Transfer",
  },
  {
    name: "Block Account",
  },
  {
    name: "Block P2P Transfer",
  },
];
