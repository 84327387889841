import axios, { AxiosError, AxiosResponse } from "axios";

export default function AxiosConfig() {
  const instanceConfig = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      common: {
        "x-Token": localStorage.getItem("tks"),
        "x-signature": process.env.REACT_APP_SIGNATURE,
      },
    },
  });

  instanceConfig.interceptors.response.use(
    (response: AxiosResponse<any>) => handleSuccess(response),
    (error: AxiosError<any>) => {
      handleError(error);
    }
  );

  function handleSuccess(response: AxiosResponse<any>) {
    return response;
  }

  function handleError(error: AxiosError<any>) {
    if (error?.response?.status === 404) {
      //move to not found
      throw error;
    }
    throw error;
  }

  return instanceConfig;
}
