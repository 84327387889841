import React, { useState, useEffect, useRef } from "react";
import {
  DashboardMainFlex,
  DashboardMainBody,
  MenuPopUp,
  AppMainBody,
  DescHeader,
  FlexedBtn,
  DateWrap,
  MenuSpace,
  DashboardInput,
  TableWrap,
  TableFlex,
  SmallTableSpace,
  TableName,
  Line,
  UserCard,
  IconCard,
  HeaderItems,
  FilterSelect,
  IconBtn,
  UserProfileCard,
  StatusCard,
} from "../../../styles/reusable/index";
import SideBarWidget from "../../mPos/reusable/sidebar";
import Header from "../../mPos/reusable/header";
import * as FeatherIcon from "react-feather";
import moment from "moment";
import axios from "axios";
import Loader from "../../mPos/reusable/loader";
import EmptyState from "../../mPos/reusable/emptyState";
import { useNavigate, useParams } from "react-router";
import BreadcrumbArea from "../../mPos/reusable/breadcrumb";
import PaginationComp from "../../mPos/reusable/pagination";
import DiscountModal from "./discountModal";
import { ClipLoader } from "react-spinners";

const DiscountUserMain = () => {
  const date = new Date();
  const navigate = useNavigate();
  const { type } = useParams();
  const loggedAdmin = localStorage.getItem("tks") || "{}";
  const exportButton: any = useRef(null);

  // States
  const [searchedItem, setSearchedItem] = useState("");
  const [activeItem, setActiveItem] = useState(-1);
  const [discountedUsers, setDiscountedUsers] = useState<any>("");
  const [discountedUsersMutable, setMutableDiscountedUsers] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  let [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState<string | number | any>("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openExport, setOpenExport] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [onProcess, setOnProcess] = useState(false);

  // Export Range

  const body = {
    headers: {
      "Content-Type": "application/json",
      "x-token": `${loggedAdmin}`,
    },
  };

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click();
      setOpenExport(false);
    }
  }, [exportData]);

  const getIndex = () => {
    switch (type) {
      case "gateway":
        return 1;
      case "posPayIn":
        return 2;
      case "posPayout":
        return 3;
      case "collection":
        return 4;
      case "disbursement":
        return 5;
    }
  };

  // Call Function
  const getDiscountedUsers = () => {
    const loggedAdmin = localStorage.getItem("tks") || "{}";

    const body = {
      headers: {
        "Content-Type": "application/json",
        "x-token": `${loggedAdmin}`,
      },
    };

    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/super/admin/business/discount/category/${getIndex()}?limit=20&page=${page}`,
        body
      )
      .then((res) => {
        setDiscountedUsers(res.data.data);
        setMutableDiscountedUsers(res.data.data);
        setTotalData(res.data.data.length);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  // Get Transactions
  useEffect(() => {
    getDiscountedUsers();
  }, []);

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page);
      getDiscountedUsers();
    }
  };

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page);
      getDiscountedUsers();
    }
  };

  const getHeader = () => {
    switch (type) {
      case "gateway":
        return "Gateway";
      case "posPayIn":
        return "POS Pay In";
      case "posPayOut":
        return "POS Pay Out";
      case "collection":
        return "Collection";
      case "disbursement":
        return "Disbursement";
      default:
        break;
    }
  };

  const deleteUser = (duid: string) => {
    setOnProcess(true);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/super/admin/business/discount/user/delete/${duid}`,
        {
          headers: {
            "x-token": `${loggedAdmin}`,
          },
        }
      )
      .then((res) => {
        setOnProcess(false);
        setActiveItem(-1);
        getDiscountedUsers();
      })
      .catch((err) => {
        setOnProcess(false);
      });
  };

  return (
    <>
      {openModal ? (
        <DiscountModal
          closeFunc={() => {
            setOpenModal(false);
            getDiscountedUsers();
          }}
          action={() => {}}
          getHeader={getHeader}
          getIndex={getIndex}
          type={`${type}`}
          onProcess={onProcess}
        />
      ) : null}
      <DashboardMainFlex onClick={() => setActiveItem(-1)}>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <BreadcrumbArea
              navName="Business Model"
              redirectUrl="/business-model"
            />
            <DescHeader>
              <h3>Business Model</h3>
              <FlexedBtn>
                <button onClick={() => setOpenModal(true)}>
                  Add discount for a user
                </button>
              </FlexedBtn>
            </DescHeader>
            <DescHeader
              style={{
                margin: "0 0 0 0",
                top: "0rem",
              }}
            >
              <h3>{getHeader()}</h3>
            </DescHeader>
            <TableWrap>
              <TableFlex>
                <SmallTableSpace>
                  <h3>Date</h3>
                </SmallTableSpace>
                <DateWrap>
                  <h3>Discounted User</h3>
                </DateWrap>
                <DateWrap>
                  <h3>Business Details</h3>
                </DateWrap>
                <SmallTableSpace>
                  <h3>Fee</h3>
                </SmallTableSpace>
                <SmallTableSpace>
                  <h3>Cap</h3>
                </SmallTableSpace>
                <SmallTableSpace>
                  <p>Action</p>
                </SmallTableSpace>
              </TableFlex>
              <Line />
              {discountedUsers !== "" ? (
                discountedUsersMutable && discountedUsersMutable.length > 0 ? (
                  <>
                    {discountedUsersMutable.map((item: any, index: number) => (
                      <TableFlex key={index}>
                        <SmallTableSpace>
                          <p>{moment(item.createdAt).format("LLL")}</p>
                        </SmallTableSpace>
                        <DateWrap>
                          <p>@{item.username}</p>
                        </DateWrap>
                        <DateWrap>
                          <UserProfileCard>
                            <div>
                              <span
                                style={{
                                  color: "#161616",
                                  fontSize: "12px",
                                }}
                              >
                                {item.businessName}
                              </span>
                              <span
                                style={{
                                  color: "#3B4CB8",
                                  fontSize: "10px",
                                  margin: "10px 0 0 0",
                                }}
                              >
                                {item.email}
                              </span>
                              {/* <p
                                                                    style={{
                                                                        color: '#161616',
                                                                        fontSize: '10px',
                                                                        fontWeight: "400",
                                                                        lineHeight: '20px'
                                                                    }}
                                                                >
                                                                    {item.accountNumber ? `${item.accountNumber}` : ''}
                                                                </p> */}
                            </div>
                          </UserProfileCard>
                        </DateWrap>
                        <SmallTableSpace>
                          <p>{item.fee}%</p>
                        </SmallTableSpace>
                        <SmallTableSpace>
                          <p>{item.cap}</p>
                        </SmallTableSpace>
                        <SmallTableSpace>
                          <FeatherIcon.MoreVertical
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveItem(index);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </SmallTableSpace>
                        {activeItem === index ? (
                          <MenuPopUp>
                            <p
                              style={{
                                color: "#F2000B",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteUser(item.duid);
                              }}
                            >
                              {onProcess ? (
                                <ClipLoader color="#F2000B" size={20} />
                              ) : (
                                "Delete"
                              )}
                            </p>
                          </MenuPopUp>
                        ) : null}
                      </TableFlex>
                    ))}
                    <PaginationComp
                      page={page}
                      setPage={setPage}
                      limit={20}
                      total={totalData}
                      incrementAction={incrementAction}
                      decrementAction={decrementAction}
                    />
                  </>
                ) : (
                  <EmptyState />
                )
              ) : (
                <Loader />
              )}
            </TableWrap>
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>
    </>
  );
};

export default DiscountUserMain;
