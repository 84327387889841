import React, { useState, useEffect } from "react";
import {
  InputField,
  DashboardMainFlex,
  DashboardMainBody,
  BalanceCard,
  AppMainBody,
  DescHeader,
  FlexedBtn,
  DashboardInput,
  TableWrap,
  TableFlex,
  SmallTableSpace,
  TableName,
  Line,
  Role,
  DateWrap,
  MenuSpace,
  UserCard,
  IconCard,
  BasicFlex,
  GreyText,
  UserProfileCard,
  GridTexts,
  ThreeSpaceGridWrap,
  SpaceWrap,
  HeroCover,
} from "../../styles/reusable/index";
import SideBarWidget from "../mPos/reusable/sidebar";
import Header from "../mPos/reusable/header";
import * as Icon from "iconsax-react";
import * as FeatherIcon from "react-feather";
import moment from "moment";
import { useParams, useNavigate } from "react-router";
import axios from "axios";
import { InputWrap } from "../../styles/authentication";
import Loader from "../mPos/reusable/loader";
import Typography from "../mPos/reusable/typography";
import { Link } from "react-router-dom";
import { AvatarImage } from "../mPos/reusable/style";
import Alert from "../mPos/reusable/alert";
import { ClipLoader } from "react-spinners";
import BreadcrumbArea from "../mPos/reusable/breadcrumb";
import { states } from "../../utils/states";
import { getKycStatus } from "../../utils/getKycStatus";
import CommaNumber from "comma-number";
import { axiosInstance } from "../../services/AxiosInstance";
import { nameCase } from "../../utils/modifiers/formatNames";

const AccountComp = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const loggedAdmin = localStorage.getItem("tks") || "{}";

  // States
  const [userInfo, setUserInfo] = useState<any>({});
  const [account, setAccount] = useState<any>({});
  const [bvn, setBvn] = useState("");

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [occupation, setOccupation] = useState("");

  const [onProcess, setOnprocess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  // Edit User Information
  const basicPayload = {
    address,
    city,
    state,
    country,
  };

  const EditUser = () => {
    setOnprocess(true);
    axiosInstance
      .put(` /admin/user/edit/${id}`, basicPayload)
      .then((res) => {
        setSuccess(true);
        setOnprocess(false);
      })
      .catch((err) => {
        setOnprocess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };

  const profileInputFields = [
    {
      name: "Home Address",
      type: "text",
      inputType: "input",
      value: address,
      setAction: (e: any) => {
        setAddress(e.target.value);
      },
    },
    {
      name: "City",
      type: "text",
      inputType: "input",
      value: city,
      setAction: (e: any) => {
        setCity(e.target.value);
      },
    },
    {
      name: "State",
      type: "",
      inputType: "select",
      miniArray: states,
      value: state,
      setAction: (e: any) => {
        setState(e.target.value);
      },
    },
    {
      name: "Country",
      type: "",
      inputType: "select",
      miniArray: [{ name: "Nigeria" }],
      value: country,
      setAction: (e: any) => {
        setCountry(e.target.value);
      },
    },
    {
      name: "Occupation",
      type: "text",
      inputType: "input",
      value: occupation,
      setAction: (e: any) => {
        setOccupation(e.target.value);
      },
    },
  ];

  useEffect(() => {
    if (id) {
      axiosInstance
        .get(`/admin/business/${id}`)
        .then((res) => {
          if (res) {
            setCity(res.data.data.city);
            setAddress(res.data.data.businessAddress);
            setState(res.data.data.state);
            setCountry(res.data.data.country);
            setOccupation(res.data.data.occupation);
          }
          setUserInfo(res.data.data);
          setAccount(res.data.data.Accounts[0]);
          setBvn(res.data.data.Bvn[0].data.idNumber);
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  }, [id]);

  return (
    <>
      {success ? (
        <Alert
          closeFunc={() => setSuccess(false)}
          img="/icons/success.png"
          message="Modified Successfully"
          miniMessage="You have successfully modified this user's information"
        />
      ) : null}
      {error ? (
        <Alert
          closeFunc={() => setError("")}
          img="/icons/error.png"
          message="An error occured!"
          miniMessage={error}
        />
      ) : null}
      <DashboardMainFlex>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <BreadcrumbArea navName="User" redirectUrl="/users" />
            <DescHeader>
              <h3>User</h3>
            </DescHeader>
            <TableWrap
              style={{
                padding: "0.5rem 15px 0 15px",
                borderRadius: "30px 30px 0 0",
              }}
            >
              <HeroCover></HeroCover>
              <TableFlex>
                <SmallTableSpace>
                  <Link to={`/profile/${id}`}>
                    <h3>Profile</h3>
                  </Link>
                </SmallTableSpace>
                <DateWrap>
                  <h3
                    style={{
                      color: "#3E3E0D",
                    }}
                  >
                    Account Information
                  </h3>
                </DateWrap>
                <DateWrap>
                  <Link to={`/profile/transactions/${id}`}>
                    <h3>Transactions</h3>
                  </Link>
                </DateWrap>
                <SmallTableSpace>
                  <Link to={`/profile/kyc/${id}`}>
                    <h3>KYC</h3>
                  </Link>
                </SmallTableSpace>
                <DateWrap>
                  <Link to={`/profile/complain-log/${id}`}>
                    <h3>Complain Log</h3>
                  </Link>
                </DateWrap>
                <SmallTableSpace>
                  <Link to={`/profile/audit-trail/${id}`}>
                    <h3>Audit</h3>
                  </Link>
                </SmallTableSpace>
              </TableFlex>
            </TableWrap>
            {userInfo && Object.keys(userInfo).length > 0 ? (
              <UserCard>
                <div>
                  <div>
                    <p>User Id</p>
                    <h3>{userInfo.bid}</h3>
                  </div>
                  <div>
                    <BasicFlex>
                      <IconCard background="#E0E3EB" color="#122466">
                        <Icon.Call />
                      </IconCard>
                      <div>
                        <GreyText>PHONE</GreyText>
                        <h3>{userInfo.phone}</h3>
                      </div>
                    </BasicFlex>
                  </div>
                  <GridTexts>
                    <p>BVN</p>
                    <h3>{bvn ? bvn : "Not Verified"}</h3>
                  </GridTexts>
                  <GridTexts>
                    <p>Account Number</p>
                    <h3
                      style={{
                        fontSize: "13px",
                      }}
                    >{`${account ? account.accountNumber : ""} | ${
                      account ? account.bankName : ""
                    }`}</h3>
                  </GridTexts>
                </div>
                <Line />
                <div>
                  <UserProfileCard>
                    {userInfo.image ? (
                      <AvatarImage src={userInfo.image} alt="Profile" />
                    ) : (
                      <IconCard
                        background="#FEF9E5"
                        color="#3E3E0D"
                        style={{
                          fontWeight: 800,
                        }}
                      >
                        {userInfo.businessName
                          ? userInfo.businessName[0].toUpperCase()
                          : "trtrtrt"}
                      </IconCard>
                    )}
                    <div>
                      <span>User</span>
                      <h3>{`${userInfo.businessName}  `}</h3>
                      <p>{userInfo.email}</p>
                    </div>
                  </UserProfileCard>
                  <GridTexts>
                    <p>Created</p>
                    <h3>{moment(userInfo.createdAt).format("LL")}</h3>
                  </GridTexts>
                  <GridTexts>
                    <p>Last Updated KYC</p>
                    <h3>
                      {userInfo.idDoc === 0
                        ? "Not Updated"
                        : moment(userInfo.user_updatedAt).format("LL")}
                    </h3>
                  </GridTexts>
                  <GridTexts>
                    <p>Status</p>
                    <h3
                      style={{
                        color: "#3E3E0D",
                      }}
                    >
                      {getKycStatus(userInfo.idDoc)}
                    </h3>
                  </GridTexts>
                  <BalanceCard>
                    <IconCard background="#4DC736" color="#fff">
                      &#8358;
                    </IconCard>
                    <GridTexts>
                      <p>Balance</p>
                      <h3>
                        &#8358;
                        {CommaNumber(userInfo ? userInfo.walletBalance : "")}
                      </h3>
                    </GridTexts>
                  </BalanceCard>
                </div>
                <SpaceWrap>
                  <Typography
                    text="Contact Information"
                    fontSize="14px"
                    color="#3E3E0D"
                  />
                  {profileInputFields.map((item, index) => (
                    <ThreeSpaceGridWrap key={index}>
                      <div>
                        <h4>{item.name}</h4>
                      </div>
                      <div>
                        <InputWrap>
                          <InputField>
                            <legend>{item.name}</legend>
                            {item.inputType === "input" ? (
                              <input
                                type={item.type}
                                placeholder={item.name}
                                autoComplete="off"
                                value={item.value}
                                onChange={item.setAction}
                              />
                            ) : (
                              <select
                                value={item.value}
                                onChange={item.setAction}
                              >
                                <option value={item.value}>
                                  {item.value
                                    ? nameCase(item.value)
                                    : item.value}
                                </option>
                                {item.miniArray?.map(
                                  (item: any, index: number) => {
                                    return (
                                      <option key={index}>{item.name}</option>
                                    );
                                  }
                                )}
                              </select>
                            )}
                          </InputField>
                        </InputWrap>
                      </div>
                      <div></div>
                    </ThreeSpaceGridWrap>
                  ))}
                  <FlexedBtn
                    style={{
                      margin: "3rem 0 0 0",
                      gap: "5px",
                    }}
                  >
                    <button onClick={() => EditUser()}>
                      {onProcess ? (
                        <ClipLoader color="#fff" size={18} />
                      ) : (
                        "Update"
                      )}
                    </button>
                    <button
                      style={{
                        border: "1px solid #3E3E0D",
                        color: "#3E3E0D",
                        background: "transparent",
                      }}
                    >
                      Reset
                    </button>
                  </FlexedBtn>
                </SpaceWrap>
              </UserCard>
            ) : (
              <Loader />
            )}
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>
    </>
  );
};

export default AccountComp;
