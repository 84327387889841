import React, { useState, useEffect, useRef } from "react";
import {
  DashboardMainFlex,
  DashboardMainBody,
  MenuPopUp,
  AppMainBody,
  DescHeader,
  FlexedBtn,
  DateWrap,
  MenuSpace,
  DashboardInput,
  TableWrap,
  TableFlex,
  SmallTableSpace,
  TableName,
  Line,
  UserCard,
  IconCard,
  HeaderItems,
  FilterSelect,
  IconBtn,
  UserProfileCard,
  StatusCard,
} from "../../styles/reusable/index";
import SideBarWidget from "../mPos/reusable/sidebar";
import Header from "../mPos/reusable/header";
import * as Icon from "iconsax-react";
import * as FeatherIcon from "react-feather";
import moment from "moment";
import CommaNumber from "comma-number";
import axios from "axios";
import Loader from "../mPos/reusable/loader";
import EmptyState from "../mPos/reusable/emptyState";
import { useNavigate } from "react-router";
import CsvDownloader from "react-csv-downloader";
import BreadcrumbArea from "../mPos/reusable/breadcrumb";
import { handleBg, handleColor } from "../../utils/colorHandle";
import PaginationComp from "../mPos/reusable/pagination";
import RangeModal from "../mPos/reusable/rangeModal";
import { paginationItemClasses } from "@mui/material";
import { axiosInstance } from "../../services/AxiosInstance";
import "../../styles/invoice.scss";
import { nameCase } from "../../utils/modifiers/formatNames";

const Invoice = () => {
  const date = new Date();
  const navigate = useNavigate();
  const loggedAdmin = localStorage.getItem("tks") || "{}";
  const exportButton: any = useRef(null);

  // States
  const [searchedItem, setSearchedItem] = useState("");
  const [activeItem, setActiveItem] = useState(-1);
  const [transactions, setTransactions] = useState<any>("");
  const [transactionsMutable, setMutableTransactions] = useState<any>([]);
  let [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState<string | number | any>("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openExport, setOpenExport] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [onProcess, setOnProcess] = useState(false);

  // Export Range

  const body = {
    headers: {
      "Content-Type": "application/json",
      "x-token": `${loggedAdmin}`,
    },
  };

  const getRangeData = (e: any) => {
    e.preventDefault();
    if (startDate && endDate) {
      setOnProcess(true);

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/range/transaction/${new Date(
            startDate
          ).getTime()}/${new Date(endDate).getTime()}`,
          body
        )
        .then((res) => {
          setOnProcess(false);
          if (res.data.statement.length > 0) {
            setExportData(res.data.statement);
          }
        })
        .catch((err) => {
          setOnProcess(false);
          if (err?.response?.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  // Search Endpoint
  const searchItem = () => {
    if (searchedItem) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/search/transactions?limit=20&page=${page}&key=${searchedItem}`,
          body
        )
        .then((res) => {
          setTransactions(res.data.data);
          setMutableTransactions(res.data.data);
          setTotalData(res.data.data.length);
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click();
      setOpenExport(false);
    }
  }, [exportData]);

  // Call Function
  const getTransactions = async () => {
    await axiosInstance
      .get(`/admin/transaction/all?limit=20&page=${page}`)
      .then((res) => {
        // console.log(res)
        setTransactions(res.data.data.data);
        setMutableTransactions(res.data.data.data);
        setTotalData(res.data.data.total);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  // Get Transactions
  useEffect(() => {
    getTransactions();
  }, []);

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page);
      getTransactions();
    }
  };

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page);
      getTransactions();
    }
  };

  // Refresh Filters
  const refreshFilter = () => {
    setSearchedItem("");
    getTransactions();
  };

  const dashboard: any = [];

  return (
    <>
      {openExport ? (
        <RangeModal
          closeFunc={() => setOpenExport(false)}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          action={getRangeData}
          onProcess={onProcess}
        />
      ) : null}
      <DashboardMainFlex onClick={() => setActiveItem(-1)}>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <BreadcrumbArea navName="Invoice" redirectUrl="/" />
            <DescHeader>
              <h3>Invoicing </h3>
            </DescHeader>

            <nav className="nav-section">
              <div className="links">
                <ul>
                  <li>All</li>
                  <li>Paid Invoice</li>
                  <li>Pending</li>
                </ul>
              </div>

              <div className="sort-section">
                Sort by: &nbsp;
                <select name="" id="">
                  <option value="">Newest to Oldest</option>
                </select>
              </div>
            </nav>

            <HeaderItems
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <DashboardInput
                wrapWidth={"40%"}
                showSearch={true}
                transform="none"
              >
                <input
                  placeholder="Search Username here"
                  value={searchedItem}
                  onChange={(e) => {
                    setSearchedItem(e.target.value);
                  }}
                />
                <i>
                  <Icon.SearchNormal1 size={18} onClick={() => searchItem()} />
                </i>
              </DashboardInput>
              <CsvDownloader
                datas={exportData}
                filename={`Transactions - ${moment(date)}`}
              >
                <button
                  ref={exportButton}
                  style={{ visibility: "hidden" }}
                ></button>
              </CsvDownloader>
              <FlexedBtn onClick={() => setOpenExport(true)}>
                <button>
                  <Icon.DocumentDownload />
                  Export
                </button>
              </FlexedBtn>
              <IconBtn
                background="#4DC736"
                color="#fff"
                onClick={() => refreshFilter()}
              >
                <Icon.ArrowRotateLeft color="#fff" />
              </IconBtn>
            </HeaderItems>

            <div className="table-section">
              <table>
                <thead>
                  <tr>
                    <td>{/* <input type="checkbox" /> */}</td>
                    <td>I.D</td>
                    <td>Date & Time</td>
                    <td>Account Details</td>
                    <td>Transaction Type</td>
                    <td>Reference No:</td>
                    <td>Amount</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>#123412451</td>
                    <td>June 1, 2020, 08:22 AM</td>
                    <td>
                      <b>Melanie Hobs</b>
                      <p>Adeife@gmail.com</p>
                    </td>
                    <td>N5000</td>
                    <td>
                      {" "}
                      <b className="status">Success</b>
                      {/* <BsThreeDotsVertical className="ms-3" /> */}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>#123412451</td>
                    <td>June 1, 2020, 08:22 AM</td>
                    <td>
                      <b>Melanie Hobs</b>
                      <p>Adeife@gmail.com</p>
                    </td>
                    <td>N5000</td>
                    <td>
                      {" "}
                      <b className="status">Success</b>
                      {/* <BsThreeDotsVertical className="ms-3" /> */}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>#123412451</td>
                    <td>June 1, 2020, 08:22 AM</td>
                    <td>
                      <b>Melanie Hobs</b>
                      <p>Adeife@gmail.com</p>
                    </td>
                    <td>N5000</td>
                    <td>
                      {" "}
                      <b className="status">Success</b>
                      {/* <BsThreeDotsVertical className="ms-3" /> */}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>#123412451</td>
                    <td>June 1, 2020, 08:22 AM</td>
                    <td>
                      <b>Melanie Hobs</b>
                      <p>Adeife@gmail.com</p>
                    </td>
                    <td>N5000</td>
                    <td>
                      {" "}
                      <b className="status">Success</b>
                      {/* <BsThreeDotsVertical className="ms-3" /> */}
                    </td>
                  </tr>
                </tbody>

                {dashboard.history?.length ? (
                  dashboard.history.map((e: any, i: number) => {
                    if (i <= 4) {
                      return (
                        <tbody key={i}>
                          <tr>
                            <td>{/* <input type="checkbox" /> */}</td>
                            <td>{e.transactionID}</td>
                            <td>{new Date(e.Date).toLocaleString()}</td>
                            <td>
                              <p className="b">
                                {e.fullName
                                  ? nameCase(e.fullName)
                                  : e.walletTag
                                  ? e.walletTag
                                  : ""}
                              </p>
                              <p>{e.bankName ? nameCase(e.bankName) : ""}</p>
                            </td>
                            <td>{e.serviceType}</td>
                            <td>{e.reference}</td>
                            <td>₦{e.amount}</td>
                            <td>
                              {" "}
                              <b
                                className={
                                  e.status === "Failed" || e.status === "failed"
                                    ? " status text-danger "
                                    : e.status === "Pending" ||
                                      e.status === "pending"
                                    ? "text-warning"
                                    : "status"
                                }
                              >
                                {e.status}
                              </b>{" "}
                              {/* <BsThreeDotsVertical className="ms-3" /> */}
                            </td>
                          </tr>
                        </tbody>
                      );
                    }
                  })
                ) : (
                  <tbody>
                    <tr>
                      <td>You have no transactions history</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>
    </>
  );
};

export default Invoice;
