import React, { useState } from "react";
import {
  ActionWrap,
  ChatAvatar,
  ChatSideBar,
  LineFlexCard,
  MiniBtn,
} from "../../styles/chat";
import { LoggedAvatar } from "../../styles/reusable/header";
import * as FeatherIcon from "react-feather";
import Typography from "../mPos/reusable/typography";
import { IconCard } from "../../styles/reusable/index";
import * as Icon from "iconsax-react";
import CreateBroadcast from "./broadcast";

const MessagesComponent = () => {
  const [openCreateBroadcast, setOpenCreateBroadcast] =
    useState<boolean>(false);

  return (
    <>
      {openCreateBroadcast ? (
        <CreateBroadcast closeFunc={() => setOpenCreateBroadcast(false)} />
      ) : null}
      <ChatSideBar>
        <LineFlexCard>
          <div>
            <LoggedAvatar>SA</LoggedAvatar>
            <Typography text="Super Admin" fontSize="12px" color="#122466" />
          </div>

          <FeatherIcon.MoreHorizontal />
        </LineFlexCard>
        <ActionWrap>
          <MiniBtn onClick={() => setOpenCreateBroadcast(true)}>
            <Icon.Microphone />
            Broadcast
          </MiniBtn>
          <IconCard background="#f3f6f8" color="#8F9AA3">
            <Icon.SearchNormal1 />
          </IconCard>
        </ActionWrap>
        <div
          style={{
            margin: "2rem 0 0 0",
          }}
        >
          {messages.length > 0 &&
            messages?.map((item, index) => (
              <LineFlexCard key={index}>
                <div>
                  <ChatAvatar src={item.avatar} alt="User" />
                  <div>
                    <Typography
                      text={item.name}
                      fontSize="13px"
                      color="#202020"
                      fontWeight={700}
                    />
                    <Typography
                      text={item.message}
                      fontSize="12px"
                      color="#202020"
                    />
                  </div>
                </div>
                <FeatherIcon.Check color="#3E3E0D" />
              </LineFlexCard>
            ))}
        </div>
      </ChatSideBar>
    </>
  );
};

export default MessagesComponent;

interface Message {
  name: string;
  avatar: string;
  message: string;
}

const messages: Message[] = [
  // {
  //     name: 'Benny Kenn',
  //     avatar: 'images/woman.png',
  //     message: 'assigned'
  // },
  // {
  //     name: 'Daniel Adewale',
  //     avatar: 'images/avatar.png',
  //     message: `What's up?`
  // },
];
