import React, { useState, useEffect } from "react";
import {
  ModalWrap,
  ModalChild,
  ModalHeader,
  DashboardInput,
  UserFlex,
  IconCard,
} from "../../styles/reusable/index";
import * as Icon from "iconsax-react";
import * as FeatherIcon from "react-feather";
import axios from "axios";
import EmptyState from "../mPos/reusable/emptyState";
import { Button } from "../../styles/reusable";
import { useNavigate } from "react-router";
import Loader from "../mPos/reusable/loader";

interface PropArgs {
  closeFunc: any;
  mode: string;
}

const BanksModal = ({ closeFunc, mode }: PropArgs) => {
  const navigate = useNavigate();
  const loggedAdmin = localStorage.getItem("tks") || "{}";
  const adminInformation: any = JSON.parse(
    localStorage.getItem("loggedAdmin") || "{}"
  );
  // States
  const [activeItem, setActiveItem] = useState(-1);
  const [banks, setBanks] = useState<any>("");
  const [mutableBanks, setMutableBanks] = useState<any>([]);
  const [onProcess, setOnProcess] = useState(false);
  const [error, setError] = useState("");

  const body = {
    headers: {
      "Content-Type": "application/json",
      "x-token": `${loggedAdmin}`,
    },
  };

    // Call Function
    const getBanks = () => {
        const loggedAdmin = localStorage.getItem('tks') || '{}';

        const body = {
            headers: {
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
        }

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/verify/banks`, body)
      .then((res) => {
        setBanks(res.data.data);
        setMutableBanks(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    getBanks();
  }, []);

  console.log(adminInformation);
  const deactivate = (item: any) => {
    if (item.active) {
      setOnProcess(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/bank/deactivate`,
          {
            code: item.id,
            bank_name: item.name,
            by: adminInformation._id,
          },
          {
            headers: {
              "x-token": `${loggedAdmin}`,
            },
          }
        )
        .then((res) => {
          setOnProcess(false);
          getBanks();
        })
        .catch((err) => {
          setOnProcess(false);
          setError(err.response.data.message);
          setTimeout(() => {
            setError("");
          }, 4000);
        });
    }
  };
  // const activate = (item:any) => {
  //     if (item.active){
  //         setOnProcess(true)
  //         axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/bank/activate`, {
  //             id: ''
  //         }, {
  //             headers: {
  //                 "x-token": `${loggedAdmin}`
  //             }
  //         }).then((res) => {
  //             setOnProcess(false);
  //             getBanks();
  //         }).catch((err) => {
  //             setOnProcess(false);
  //             setError(err.response.data.message)
  //             setTimeout(() => {
  //                 setError('')
  //             }, 4000)
  //         })
  //     }
  // }

    return(
        <>
            <ModalWrap>
                <ModalChild>
                    <section>
                        <ModalHeader>
                            <h3>Bank</h3>
                            <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                        </ModalHeader>
                        <DashboardInput showSearch={true}>
                            <input
                                placeholder='Search Banks'
                            />
                            <i><Icon.SearchNormal1 size={18} /></i>
                        </DashboardInput>
                        {
                            banks !== '' ?
                                mutableBanks && mutableBanks.length > 0 ?
                                    <>
                                        {
                                        mutableBanks.map((item:any, index:number) => (
                                            <UserFlex
                                                key={index}
                                            >
                                                <div>

                                                    <div>
                                                        <h3>{item.name ? item.name : 'No Name'}</h3>
                                                    </div>
                                                </div>
                                                <Button
                                                    width='5rem'
                                                    bg='var(--primary-color)'
                                                    color='#fff'
                                                    type='submit'
                                                    onClick={() => {
                                                        item.active ?
                                                            deactivate(item)
                                                            : () => {}
                                                    }}
                                                >
                                                    {
                                                        item.active ?
                                                            "Deactivate"
                                                            :
                                                            "Activate"
                                                    }
                                                </Button>
                                            </UserFlex>
                                            ))
                                        }
                                    </>
                                    :
                                    <EmptyState />
                                :
                                <Loader />
                            }
                    </section>
                </ModalChild>
            </ModalWrap>
        </>
    )
}

export default BanksModal;

