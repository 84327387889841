/* eslint-disable space-before-function-paren */
/* eslint-disable semi */
/* eslint-disable quotes */
import React from "react";
import "../../styles/pos/requests.scss";
import { BsDot } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";

export default function RequestsTable() {
  return (
    <div className="requests-table-container">
      <table>
        <thead>
          <td>Date Submitted</td>
          <td>Date Approved</td>
          <td>User</td>
          <td>POS Type</td>
          <td>Location</td>
          <td>Quantity</td>
          <td>Status</td>
          <td>Action</td>
        </thead>

        <tbody>
          <tr>
            <td>24-03-2013, 05:53PM</td>
            <td>24-03-2013, 05:53PM</td>

            <td className="profile">
              <div className="avatar"></div>
              <div className="details">
                <p>Jane Doe Joanne</p>
                <p>jane@doedoe@gmail.com</p>
              </div>
            </td>

            <td>Mongoro Amal</td>
            <td>Abuja, Nigeria</td>
            <td>28</td>
            <td className="status">
              {" "}
              <BsDot className="icon" /> Pending
            </td>
            <td>
              {" "}
              <b>View</b> <IoMdMore />{" "}
            </td>
          </tr>
          <tr>
            <td>24-03-2013, 05:53PM</td>
            <td>24-03-2013, 05:53PM</td>

            <td className="profile">
              <div className="avatar"></div>
              <div className="details">
                <p>Jane Doe Joanne</p>
                <p>jane@doedoe@gmail.com</p>
              </div>
            </td>

            <td>Mongoro Amal</td>
            <td>Abuja, Nigeria</td>
            <td>28</td>
            <td className="success">
              {" "}
              <BsDot className="icon" /> Approved
            </td>
            <td>
              {" "}
              <b>View</b> <IoMdMore />{" "}
            </td>
          </tr>
          <tr>
            <td>24-03-2013, 05:53PM</td>
            <td>24-03-2013, 05:53PM</td>

            <td className="profile">
              <div className="avatar"></div>
              <div className="details">
                <p>Jane Doe Joanne</p>
                <p>jane@doedoe@gmail.com</p>
              </div>
            </td>

            <td>Mongoro Amal</td>
            <td>Abuja, Nigeria</td>
            <td>28</td>
            <td className="failed">
              {" "}
              <BsDot className="icon" /> Rejected
            </td>
            <td>
              {" "}
              <b>View</b> <IoMdMore />{" "}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
