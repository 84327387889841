import { ModalWrap, ModalChild, ModalHeader, InputField, UnderlayText } from "../../../styles/reusable/index";
import * as FeatherIcon from 'react-feather';
import { Button } from "../../../styles/reusable";
import { ClipLoader } from 'react-spinners';
import { useState } from "react";
import { ArrowForward } from "iconsax-react";
import { ArrowForwardSharp } from "@mui/icons-material";
import { axiosInstance } from "../../../services/AxiosInstance";
interface PropArgs {
    closeFunc: any;
    businessId: string | any;
    setEmail?: any;
    bussinessName: string;
    setDescription?: React.Dispatch<React.SetStateAction<string>>;
    action?: any;
}

export default function CreateSubAccount(
    { closeFunc,
        businessId,
        bussinessName,
        action
    }: PropArgs) {


    const [thisEmail, setThisEmail] = useState("")
    const [onProcess, setOnProcess] = useState<boolean>(false)
    const [validatorOnProcess, setValidatorOnProcess] = useState<boolean>(false)

    const [subAccountPayload, setSubAccountPayload] = useState<{ [key: string]: string }>({
        email: "",
        subAccountName: "",
        bid: businessId,
        bussinessName
    })

    const [error, setError] = useState("")

    async function handleCreateSubAccount(payload?: { [key: string]: string | number }) {

        setOnProcess(true)
        try {
            await axiosInstance.post("/super/admin/sub/create", subAccountPayload).then((resp) => {
                setOnProcess(false)
                console.log(resp.data)
            })
        } catch (error) {
            setOnProcess(false)
            console.log(error)
        }
    }

    async function handleVerifySubAccount() {
        if (subAccountPayload.email) {
            setValidatorOnProcess(true)
            try {
                await axiosInstance.get(`/admin/business/verify/email/${subAccountPayload.email}`).then((resp) => {
                    if (resp.data.status) {
                        setSubAccountPayload({ ...subAccountPayload, subAccountName: resp.data.data.businessName })

                    } else {
                        setError("There's no business registered with the provided email")

                    }
                    setValidatorOnProcess(false)
                })
            } catch (error) {
                setError("Unable to verify business. Please check your network connectivity and try again. Kindly contact support if issue persists")
                setValidatorOnProcess(false)
            }
        } else {
            setError("Required field: Enter the email of the business to be invited")
        }
    }



    return (
        <div>
            <ModalWrap>
                <ModalChild>
                    <ModalHeader>
                        <h3>Create subaccount</h3>
                        <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                    </ModalHeader>
                    <form onSubmit={(e) => action(e)}>


                        <div className="input-area ">
                            <div className="label">
                                <label htmlFor="">Subaccount Email</label>
                            </div>
                            <div className="input flex">
                                <input type="text" className="email-input"
                                    required
                                    placeholder={'Enter subaccount email address'}
                                    value={subAccountPayload.email}
                                    onChange={(e) => {
                                        setError("")
                                        setSubAccountPayload({ ...subAccountPayload, email: e.target.value, subAccountName: "" })

                                    }}

                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleVerifySubAccount()
                                        }
                                    }}
                                />
                                <div className="enter"
                                    onClick={() => handleVerifySubAccount()}
                                >
                                    {validatorOnProcess ? <ClipLoader color='#fff' size={18} /> : <ArrowForwardSharp className="icon" />}
                                </div>

                            </div>
                            <div className="error">
                                {error}
                            </div>
                        </div>





                        <InputField className="disabled-input">
                            <legend>Subaccount Name</legend>
                            <input
                                placeholder={'Account attached to this email'}
                                value={subAccountPayload.subAccountName}
                                className="disabled-input"
                                disabled

                            />


                        </InputField>




                        <Button
                            width='100%'
                            bg={subAccountPayload.email && subAccountPayload.subAccountName ? `var(--primary-color)` : `grey`}
                            color='#fff'
                            type='button'
                            disabled={onProcess || !subAccountPayload.email || !subAccountPayload.subAccountName ? true : false}
                            style={onProcess ? {
                                cursor: 'not-allowed',
                            } : {}}
                            onClick={() => {
                                handleCreateSubAccount()
                            }}
                        >
                            {onProcess ? <ClipLoader color='#fff' size={18} /> : 'Create subaccount'}
                        </Button>


                    </form>
                </ModalChild>
            </ModalWrap>
        </div>
    )
}