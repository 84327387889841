import React, { useState } from "react";
import "./styles/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./components/404";
import LoginPage from "./pages/login";
import ConfirmEmailPage from "./pages/confirmEmail";
import Dashboard from "./pages/dashboard";
import ChangePwdPage from "./pages/changePassword";
import Access from "./components/access";
import UserAccessPage from "./pages/userAccess";
import SingleUserPage from "./pages/singleUser";
import LoginActivitiesPage from "./pages/loginActivities";
import ChatMainPage from "./pages/chatMain";
import TicketsPage from "./pages/tickets";
import SingleTicketsPage from "./pages/single-ticket";
import TransactionsPage from "./pages/transactions";
import WithdrawalPage from "./pages/withdrawal";
import MPosPage from "./pages/mPos";
import SettingsPage from "./pages/settings";
import ControlPanelPage from "./pages/controlPanel";
import BusinessAdvisoryPage from "./pages/businessAdvisory";
import KYCPage from "./pages/kyc";
import Users from "./pages/users";
import ProfilePage from "./pages/profile";
import AccountPage from "./pages/account";
import UserTransactionsPage from "./pages/profileTransaction";
import { PreventToAuth, PreventToDefault } from "./protectedRoute";
import CreateSuperAdminPage from "./pages/createSuper";
import AuditTrailComp from "./components/profile/audit";
import UserKYCComp from "./components/profile/kyc";
import UserLOGComp from "./components/profile/complaintLog";
import Account from "./pages/accountIndex";
import BusinessModel from "./components/account/businessModel";
import DiscountUserMain from "./components/account/subBusiness/discountedUsers";
import IdentityLookup from "./components/account/lookup/identityLookup";
import FundAccount from "./components/account/fundAccount";
import Blockings from "./components/account/blocking";
import BlockedUsersMain from "./components/account/blockedUsers";
import Providers from "./components/account/provider";
import TransferUpgrade from "./components/account/transferUpgrade";
import ResetInformation from "./components/account/resetInformation";
import DebitAccount from "./components/account/debitAccount";
// import Permissions from './pages/Permissions';
import "./styles/global.scss";
import { Provider } from "react-redux";
import store from "./redux/store";
import { getSelf } from "./services/getSelf";
import PaymentGatewayExternal from "./pages/PaymentGatewayExternal";
import InvoicePage from "./pages/invoice";
import SubAccountsPage from "./pages/subAccounts";
import SingleAccount from "./components/sub_accounts/singleAccount";
import POSRequests from "./components/mPos/POSRequests";

function App() {
  // getSelf()

  return (
    <>
      <Provider store={store}>
        <Router>
          <Routes>
            {/* Authentication Routes */}
            <Route path="/login" element={<PreventToAuth />}>
              <Route path="/login" element={<LoginPage />} />
            </Route>
            <Route path="/confirm-email/:email" element={<PreventToAuth />}>
              <Route
                path="/confirm-email/:email"
                element={<ConfirmEmailPage />}
              />
            </Route>
            <Route path="/change-password/:email" element={<PreventToAuth />}>
              <Route
                path="/change-password/:email"
                element={<ChangePwdPage />}
              />
            </Route>
            <Route path="/super/auth/create" element={<PreventToAuth />}>
              <Route
                path="/super/auth/create"
                element={<CreateSuperAdminPage />}
              />
            </Route>

            {/* Other Routes which is protected by logged adin function*/}
            <Route path="/" element={<PreventToDefault />}>
              <Route path="/" element={<Dashboard />} />
            </Route>
            <Route path="/account" element={<PreventToDefault />}>
              <Route path="/account" element={<Account />} />
            </Route>

            {/* <Route path='/permissions' element={<PreventToDefault />}>
            <Route path='/permissions' element={<Permissions />} />
          </Route> */}

            <Route path="/admin-access" element={<PreventToDefault />}>
              <Route path="/admin-access" element={<Access />} />
            </Route>
            <Route path="/user-access" element={<PreventToDefault />}>
              <Route path="/user-access" element={<UserAccessPage />} />
            </Route>
            <Route path="/single-user" element={<PreventToDefault />}>
              <Route path="/single-user" element={<SingleUserPage />} />
            </Route>
            <Route path="/audit-trails" element={<PreventToDefault />}>
              <Route path="/audit-trails" element={<LoginActivitiesPage />} />
            </Route>
            <Route path="/chats" element={<PreventToDefault />}>
              <Route path="/chats" element={<ChatMainPage />} />
            </Route>
            <Route path="/tickets" element={<PreventToDefault />}>
              <Route path="/tickets" element={<TicketsPage />} />
            </Route>
            <Route path="/ticket-information" element={<PreventToDefault />}>
              <Route
                path="/ticket-information"
                element={<SingleTicketsPage />}
              />
            </Route>
            <Route path="/transactions" element={<PreventToDefault />}>
              <Route path="/transactions" element={<TransactionsPage />} />
            </Route>
            <Route path="/invoice" element={<PreventToDefault />}>
              <Route path="/invoice" element={<InvoicePage />} />
            </Route>
            <Route path="/subaccounts" element={<PreventToDefault />}>
              <Route path="/subaccounts" element={<SubAccountsPage />} />
            </Route>
            <Route
              path="/subaccounts/single/:id"
              element={<PreventToDefault />}
            >
              <Route
                path="/subaccounts/single/:id"
                element={<SingleAccount />}
              />
            </Route>
            <Route path="/withdrawals" element={<PreventToDefault />}>
              <Route path="/withdrawals" element={<WithdrawalPage />} />
            </Route>
            <Route path="/mpos" element={<PreventToDefault />}>
              <Route path="" element={<MPosPage />} />
              <Route
                path="requests"
                element={<POSRequests/>}
              />
            </Route>
            <Route path="/settings" element={<PreventToDefault />}>
              <Route path="/settings" element={<SettingsPage />} />
            </Route>
            <Route path="/control-panel" element={<PreventToDefault />}>
              <Route path="/control-panel" element={<ControlPanelPage />} />
            </Route>
            <Route path="/users" element={<PreventToDefault />}>
              <Route path="/users" element={<Users />} />
            </Route>
            <Route path="/community" element={<PreventToDefault />}>
              <Route path="/community" element={<BusinessAdvisoryPage />} />
            </Route>
            <Route path="/kyc" element={<PreventToDefault />}>
              <Route path="/kyc" element={<KYCPage />} />
            </Route>
            <Route path="/profile/:id" element={<PreventToDefault />}>
              <Route path="/profile/:id" element={<ProfilePage />} />
            </Route>
            <Route
              path="/profile/account-information/:id"
              element={<PreventToDefault />}
            >
              <Route
                path="/profile/account-information/:id"
                element={<AccountPage />}
              />
            </Route>
            <Route
              path="/profile/transactions/:id"
              element={<PreventToDefault />}
            >
              <Route
                path="/profile/transactions/:id"
                element={<UserTransactionsPage />}
              />
            </Route>
            <Route
              path="/profile/audit-trail/:id"
              element={<PreventToDefault />}
            >
              <Route
                path="/profile/audit-trail/:id"
                element={<AuditTrailComp />}
              />
            </Route>
            <Route path="/profile/kyc/:id" element={<PreventToDefault />}>
              <Route path="/profile/kyc/:id" element={<UserKYCComp />} />
            </Route>
            <Route
              path="/profile/complain-log/:id"
              element={<PreventToDefault />}
            >
              <Route
                path="/profile/complain-log/:id"
                element={<UserLOGComp />}
              />
            </Route>
            <Route path="/identity-lookup" element={<PreventToDefault />}>
              <Route path="/identity-lookup" element={<IdentityLookup />} />
            </Route>
            <Route path="/fund-account" element={<PreventToDefault />}>
              <Route path="/fund-account" element={<FundAccount />} />
            </Route>
            <Route path="/debit-account" element={<PreventToDefault />}>
              <Route path="/debit-account" element={<DebitAccount />} />
            </Route>
            <Route path="/transfer-upgrade" element={<PreventToDefault />}>
              <Route path="/transfer-upgrade" element={<TransferUpgrade />} />
            </Route>
            <Route path="/reset-information" element={<PreventToDefault />}>
              <Route path="/reset-information" element={<ResetInformation />} />
            </Route>
            <Route path="/blockings" element={<PreventToDefault />}>
              <Route path="/blockings" element={<Blockings />} />
            </Route>
            <Route path="/blocked-users/:index" element={<PreventToDefault />}>
              <Route
                path="/blocked-users/:index"
                element={<BlockedUsersMain />}
              />
            </Route>

            {/* Business Model Routes */}
            <Route path="/business-model" element={<PreventToDefault />}>
              <Route path="/business-model" element={<BusinessModel />} />
            </Route>

            <Route path="/pay" element={<PreventToDefault />}>
              <Route path="/pay" element={<PaymentGatewayExternal />} />
            </Route>

            <Route path="/providers" element={<PreventToDefault />}>
              <Route path="/providers" element={<Providers />} />
            </Route>
            <Route
              path="/discounted-users/:type"
              element={<PreventToDefault />}
            >
              <Route
                path="/discounted-users/:type"
                element={<DiscountUserMain />}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
