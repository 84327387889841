import React, { useState, useEffect } from "react";
import {
  StatusCard,
  DashboardMainFlex,
  DashboardMainBody,
  BalanceCard,
  DocumentCard,
  AppMainBody,
  DescHeader,
  FlexedBtn,
  DashboardInput,
  TableWrap,
  TableFlex,
  SmallTableSpace,
  TableName,
  Line,
  Role,
  DateWrap,
  MenuSpace,
  UserCard,
  IconCard,
  BasicFlex,
  GreyText,
  UserProfileCard,
  GridTexts,
  ThreeSpaceGridWrap,
  SpaceWrap,
  HeroCover,
  PdfDocumentCard,
  PdfDocumentContainer,
  PdfDocumentOverlay,
  ViewDocumenentContiner,
  ViewPdfDocumentCard,
  ModalWrap,
  ModalChild,
  ModalHeader,
} from "../../styles/reusable/index";
import SideBarWidget from "../mPos/reusable/sidebar";
import Header from "../mPos/reusable/header";
import * as Icon from "iconsax-react";
import * as FeatherIcon from "react-feather";
import moment from "moment";
import { useParams, useNavigate } from "react-router";
import axios from "axios";
import Loader from "../mPos/reusable/loader";
import { Link } from "react-router-dom";
import CommaNumber from "comma-number";
import EmptyState from "../mPos/reusable/emptyState";
import { AvatarImage } from "../mPos/reusable/style";
import BreadcrumbArea from "../mPos/reusable/breadcrumb";
import { ClipLoader } from "react-spinners";
import Alert from "../mPos/reusable/alert";
import { getKycStatus } from "../../utils/getKycStatus";
import { axiosInstance } from "../../services/AxiosInstance";
import PDF from "../../assets/icons/file.svg";

const UserKYCComp = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const loggedAdmin = localStorage.getItem("tks") || "{}";

  // States
  const [userInfo, setUserInfo] = useState<any>({});
  const [account, setAccount] = useState<any>({});
  const [bvn, setBvn] = useState("");

  // States
  const [kyc, setKyc] = useState<any>("");
  const [kycMutable, setMutableKyc] = useState<any>([]);
  const [onProcess, setOnProcess] = useState(false);
  const [rejectProcess, setRejectProcess] = useState(false);
  const [acceptSucces, setAcceptSuccess] = useState(false);
  const [rejectSuccess, setRejectSuccess] = useState(false);
  const [error, setError] = useState("");
  const [expanded, setExpanded] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);

  const handlePdfClick = () => {
    setExpanded((prev) => !prev);
  };

  // Get KYC
  useEffect(() => {
    axiosInstance
      .get(`/admin/kyc/business/documents/${id}`)
      .then((res) => {
        setKyc(res.data.data);
        setMutableKyc(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }, []);

  const getUser = () => {
    if (id) {
      axiosInstance
        .get(`/admin/business/${id}`)
        .then((res) => {
          setUserInfo(res.data.data);
          setAccount(res.data.data.Accounts[0]);
          setBvn(res.data.data.Bvn[0].data.idNumber);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  // Get Transactions
  useEffect(() => {
    getUser();
  }, [id]);

  const basicPayload = {
    bid: id,
  };

  // Accept or reject
  const Accept = () => {
    setOnProcess(true);
    axiosInstance
      .post(`/admin/kyc/accept`, basicPayload)
      .then(() => {
        setAcceptSuccess(true);
        setOnProcess(false);
        getUser();
      })
      .catch((err) => {
        setOnProcess(false);
        setError(err.response.data.message);
        getUser();
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };

  const Reject = () => {
    setRejectProcess(true);
    axiosInstance
      .post(`/admin/kyc/reject`, basicPayload)
      .then(() => {
        setRejectSuccess(true);
        setRejectProcess(false);
      })
      .catch((err) => {
        setRejectProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };

  return (
    <>
      {acceptSucces ? (
        <Alert
          closeFunc={() => setAcceptSuccess(false)}
          img="/icons/success.png"
          message="Modified Successfully"
          miniMessage="You have successfully accepted this user's kyc"
        />
      ) : null}
      {rejectSuccess ? (
        <Alert
          closeFunc={() => setRejectSuccess(false)}
          img="/icons/success.png"
          message="Modified Successfully"
          miniMessage="You have successfully rejected this user's kyc"
        />
      ) : null}
      {error ? (
        <Alert
          closeFunc={() => setError("")}
          img="/icons/error.png"
          message="An error occured!"
          miniMessage={error}
        />
      ) : null}
      <DashboardMainFlex>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <BreadcrumbArea navName="User" redirectUrl="/users" />
            <DescHeader>
              <h3>User</h3>
            </DescHeader>
            <TableWrap
              style={{
                padding: "0.5rem 15px 0 15px",
                borderRadius: "30px 30px 0 0",
              }}
            >
              <HeroCover></HeroCover>
              <TableFlex>
                <SmallTableSpace>
                  <Link to={`/profile/${id}`}>
                    <h3>Profile</h3>
                  </Link>
                </SmallTableSpace>
                <DateWrap>
                  <Link to={`/profile/account-information/${id}`}>
                    <h3>Account Information</h3>
                  </Link>
                </DateWrap>
                <DateWrap>
                  <Link to={`/profile/transactions/${id}`}>
                    <h3>Transactions</h3>
                  </Link>
                </DateWrap>
                <SmallTableSpace>
                  <h3
                    style={{
                      color: "#3E3E0D",
                    }}
                  >
                    KYC
                  </h3>
                </SmallTableSpace>
                <DateWrap>
                  <Link to={`/profile/complain-log/${id}`}>
                    <h3>Complain Log</h3>
                  </Link>
                </DateWrap>
                <SmallTableSpace>
                  <Link to={`/profile/audit-trail/${id}`}>
                    <h3>Audit</h3>
                  </Link>
                </SmallTableSpace>
              </TableFlex>
            </TableWrap>
            {userInfo && Object.keys(userInfo).length > 0 ? (
              <>
                <UserCard>
                  <div>
                    <div>
                      <p>User Id</p>
                      <h3>{userInfo.bid}</h3>
                    </div>
                    <div>
                      <BasicFlex>
                        <IconCard background="#E0E3EB" color="#122466">
                          <Icon.Call />
                        </IconCard>
                        <div>
                          <GreyText>PHONE</GreyText>
                          <h3>{userInfo.phone}</h3>
                        </div>
                      </BasicFlex>
                    </div>
                    <GridTexts>
                      <p>BVN</p>
                      <h3>{bvn ? bvn : "Not Verified"}</h3>
                    </GridTexts>
                    <GridTexts>
                      <p>Account Number</p>
                      <h3
                        style={{
                          fontSize: "13px",
                        }}
                      >{`${account ? account.accountNumber : ""} | ${
                        account ? account.bankName : ""
                      }`}</h3>
                    </GridTexts>
                  </div>
                  <Line />
                  <div>
                    <UserProfileCard>
                      {userInfo.image ? (
                        <AvatarImage src={userInfo.image} alt="Profile" />
                      ) : (
                        <IconCard
                          background="#FEF9E5"
                          color="#3E3E0D"
                          style={{
                            fontWeight: 800,
                          }}
                        >
                          {userInfo.businessName
                            ? userInfo.businessName[0].toUpperCase()
                            : ""}
                        </IconCard>
                      )}
                      <div>
                        <span>User</span>
                        <h3>{`${userInfo.businessName} `}</h3>
                        <p>{userInfo.email}</p>
                      </div>
                    </UserProfileCard>
                    <GridTexts>
                      <p>Created</p>
                      <h3>{moment(userInfo.createdAt).format("LL")}</h3>
                    </GridTexts>
                    <GridTexts>
                      <p>Last Updated KYC</p>
                      <h3>
                        {userInfo.idDoc === 0
                          ? "Not Updated"
                          : moment(userInfo.user_updated_at).format("LL")}
                      </h3>
                    </GridTexts>
                    <GridTexts>
                      <p>Status</p>
                      <h3
                        style={{
                          color: "#3E3E0D",
                        }}
                      >
                        {getKycStatus(userInfo.idDoc)}
                      </h3>
                    </GridTexts>
                    <BalanceCard>
                      <IconCard background="#4DC736" color="#fff">
                        &#8358;
                      </IconCard>
                      <GridTexts>
                        <p>Balance</p>
                        <h3>
                          &#8358;
                          {CommaNumber(userInfo ? userInfo.walletBalance : "")}
                        </h3>
                      </GridTexts>
                    </BalanceCard>
                  </div>
                  <SpaceWrap></SpaceWrap>
                </UserCard>
                <TableWrap>
                  <Line />
                  {kyc && Object.keys(kyc).length > 0 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "40px",
                          flexWrap: "wrap",
                        }}
                      >
                        <h4>{kyc.type}</h4>
                        <p
                          style={{
                            color: "#216FED",
                          }}
                        >
                          {kyc ? kyc.idNumber : ""}
                        </p>
                      </div>
                      <h3>Uploaded Document</h3>

                      {kyc.document.map((doc: any, i: number) => (
                        <>
                          {doc.cacCertificate && (
                            <PdfDocumentContainer expanded={expanded}>
                              <PdfDocumentOverlay
                                expanded={expanded}
                                onClick={handlePdfClick}
                              />
                              <PdfDocumentCard
                                src={doc.cacCertificate}
                                width={expanded ? "100%" : "20rem"}
                                height={expanded ? "800px" : "auto"}
                                expanded={expanded}
                              />
                            </PdfDocumentContainer>
                          )}
                          {/* <DocumentCard
                            key={i}
                            src={doc.cacCertificate}
                            alt="cac certificate"
                          /> */}
                          {doc.meMert && (
                            <PdfDocumentContainer expanded={expanded}>
                              <PdfDocumentOverlay
                                expanded={expanded}
                                onClick={handlePdfClick}
                              />
                              <PdfDocumentCard
                                src={doc.meMart}
                                width={expanded ? "100%" : "20rem"}
                                height={expanded ? "800px" : "auto"}
                                expanded={expanded}
                                onClick={handlePdfClick}
                              />
                            </PdfDocumentContainer>
                          )}
                          {/* <DocumentCard
                            key={i}
                            src={doc.meMart}
                            alt="me mart"
                          /> */}
                          {doc.forms && (
                            <PdfDocumentContainer expanded={expanded}>
                              <PdfDocumentOverlay
                                expanded={expanded}
                                onClick={handlePdfClick}
                              />
                              <PdfDocumentCard
                                src={doc.forms}
                                width={expanded ? "100%" : "20rem"}
                                height={expanded ? "800px" : "auto"}
                                expanded={expanded}
                                onClick={handlePdfClick}
                              />
                            </PdfDocumentContainer>
                          )}
                          {/* <DocumentCard key={i} src={doc.forms} alt="forms" /> */}
                          {doc.utility && (
                            <PdfDocumentContainer expanded={expanded}>
                              <PdfDocumentOverlay
                                expanded={expanded}
                                onClick={handlePdfClick}
                              />
                              <DocumentCard
                                key={i}
                                src={doc.utility}
                                alt="utility"
                                width={expanded ? "100%" : "20rem"}
                                height={expanded ? "800px" : "auto"}
                                expanded={expanded}
                                onClick={handlePdfClick}
                              />
                            </PdfDocumentContainer>
                          )}
                          {/* {doc.utility && (
                            <PdfDocumentCard
                              key={i}
                              src={doc.utility}
                              //   alt="utility"
                            />
                          )} */}
                        </>
                      ))}
                    </div>
                  ) : (
                    <EmptyState />
                  )}
                </TableWrap>
              </>
            ) : (
              <Loader />
            )}
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>
    </>
  );
};

export default UserKYCComp;
