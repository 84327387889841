import { axiosInstance } from "./AxiosInstance";

export async function getSelf() {
  let response:{[key:string]:string} ={} ;
  let error = "";
  try {
    await axiosInstance.get("/admin/single").then((resp) => {
      response = resp.data;  
    });
  } catch (err: any) {
    error = err;
  }


  return {response, error}
}

 