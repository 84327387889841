import React, { useState, useEffect } from "react";
import {
  ModalWrap,
  ModalChild,
  ModalHeader,
  InputField,
  UnderlayText,
  UserProfileCard,
  IconCard,
} from "../../styles/reusable/index";
import * as FeatherIcon from "react-feather";
import { Button } from "../../styles/reusable";
import { AvatarImage } from "../mPos/reusable/style";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router";
import { CustomStyles } from "../../utils/selectStyle";
import { ClipLoader } from "react-spinners";
import Alert from "../mPos/reusable/alert";
import { axiosInstance } from "../../services/AxiosInstance";

interface PropArgs {
  closeFunc: any;
  closeModal: any;
}

const CreateProvider = ({ closeFunc, closeModal }: PropArgs) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [onProcess, setOnProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const loggedAdmin = localStorage.getItem("tks") || "{}";

  const basicPayload = {
    vendorName: name,
  };

  // Call Function
  const validateID = (e: any) => {
    if (name) {
      e.preventDefault();
      setOnProcess(true);
      axiosInstance
        .post(`/super/admin/business/provider`, basicPayload)
        .then((res) => {
          setOnProcess(false);
          setSuccess(true);
        })
        .catch((err) => {
          setOnProcess(false);
          setError(err.response.data.message);
          if (err?.response?.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    } else {
      setError("Field is required");
    }
  };

  return (
    <>
      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            closeFunc();
          }}
          img="/icons/success.png"
          message="Modified Successfully"
          miniMessage="You have created this provider successfully!"
        />
      ) : null}

      <ModalWrap style={success ? { visibility: "hidden" } : {}}>
        <ModalChild>
          <ModalHeader>
            <h3>Service Provider</h3>
            <i>
              <FeatherIcon.X onClick={() => closeModal()} />
            </i>
          </ModalHeader>
          <form>
            <UnderlayText>Please enter the service provider name.</UnderlayText>
            <InputField>
              <legend>Vendor name</legend>
              <input
                placeholder={"Vendor name"}
                type="text"
                onChange={(e) => {
                  setError("");
                  setName(e.target.value);
                }}
                required
              />
            </InputField>
            {error ? (
              <p
                style={{
                  color: "#c82b32",
                  fontSize: "13px",
                }}
              >
                {error}
              </p>
            ) : null}
            <Button
              onClick={(e) => validateID(e)}
              width="100%"
              bg="#3E3E0D"
              color="#fff"
              type="button"
              disabled={onProcess}
              style={
                onProcess
                  ? {
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              {onProcess ? <ClipLoader color="#fff" /> : "Proceed"}
            </Button>
          </form>
        </ModalChild>
      </ModalWrap>
    </>
  );
};

export default CreateProvider;
