import React, { useEffect, useState } from "react";
import {
  LogoImageWrap,
  LogoImage,
  MainWidget,
  NavHeader,
  NavOption,
  NavItem,
  CloseBtn,
} from "../../../styles/reusable/sidebar";
import * as Icon from "iconsax-react";
import { NavLink, Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import * as FeatherIcon from "react-feather";
import { useAppDispatch, useAppSelector } from "../../../hooks/customRedux";
import { RootState } from "../../../redux/store";
import { getAdmin } from "../../../redux/slices/loggedUser";

interface SideBarProps {
  closeNav?: any;
  mobileDisplay?: string;
}

const SideBarWidget = ({ closeNav, mobileDisplay }: SideBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const current = location.pathname;

  // Routes current and active defining.

  const overviewLinks = ["/"];
  const overviewActive = overviewLinks.includes(current);
  const withdrawalLinks = ["/withdrawals"];
  const withdrawalActive = withdrawalLinks.includes(current);
  const transactionLinks = ["/transactions"];
  const transactionActive = transactionLinks.includes(current);
  const ticketLinks = ["/tickets"];
  const ticketActive = ticketLinks.includes(current);
  const businessAdvisoryLinks = ["/community"];
  const businessAdvisoryActive = businessAdvisoryLinks.includes(current);
  const mPosLinks = ["/mpos"];
  const mPosActive = mPosLinks.includes(current);
  const chatLinks = ["/chats"];
  const chatActive = chatLinks.includes(current);
  const settingLinks = ["/settings"];
  const settingActive = settingLinks.includes(current);
  const serviceLinks = ["/kyc", "/user-access", "admin-access"];
  const serviceActive = serviceLinks.includes(current);
  const accountLinks = ["/account"];
  const accountActive = accountLinks.includes(current);
  const adminLinks = ["/admin"];
  const adminActive = adminLinks.includes(current);

  // Log User Out of app

  const redirectAfterLogOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  // Open Option
  const [openOption, setOpenOption] = useState(false);
  const [activeItem, setActiveItem] = useState<number>(-1);
  const handleOpenOption = (index: number) => {
    setOpenOption(!openOption);
    setActiveItem(index);
  };
  const [access, setAccess] = useState<number[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAdmin());
  }, [dispatch]);

  const { Access: adminAccess, supers: adminDetails } = useAppSelector(
    (state: RootState) => state.loggedAdmin
  );
  useEffect(() => {
    setAccess(adminAccess);
    setAccess((prevAccess) => {
      if (Array.isArray(prevAccess)) {
        return [...prevAccess, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      } else {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }
    });

    // if(adminDetails?.email === "v.onoja@reeflimited.com" || adminDetails?.email === "onoja.jsdev@gmail.com"){
    //     setAccess((prevAccess) => [...prevAccess, 1,2,3,4,5,6, 7, 8, 9, 10, 11]);

    // }
  }, [adminAccess]);

  const navList = [
    {
      name: "Dashboard",
      icon: Icon.Home,
      link: "/",
      activeClass: overviewActive,
      action: () => {},
      key: 1,
    },
    {
      name: "Withdrawal",
      icon: Icon.EmptyWallet,
      link: "/withdrawals",
      activeClass: withdrawalActive,
      action: () => {},
      key: 2,
    },
    {
      name: "Transactions",
      icon: Icon.ArrangeVertical,
      link: "/transactions",
      activeClass: transactionActive,
      action: () => {},
      key: 3,
    },
    {
      name: "Services",
      icon: Icon.Category2,
      hasOption: true,
      activeClass: serviceActive,
      action: (index: number) => handleOpenOption(index),
      key: 4,
    },
    {
      name: "Invoicing",
      link: "/invoice",
      icon: Icon.Category2,
      hasOption: false,
      activeClass: serviceActive,
      // action: (index:number) => handleOpenOption(index),
      action: () => {},
      key: 5,
    },
    {
      name: "Manage Sub-accounts",
      link: "/subaccounts",
      icon: Icon.Category2,
      hasOption: false,
      activeClass: serviceActive,
      // action: (index:number) => handleOpenOption(index),
      action: () => {},
      key: 5,
    },
    {
      name: "Tickets",
      icon: Icon.Ticket2,
      link: "/tickets",
      activeClass: ticketActive,
      action: () => {},
      key: 6,
    },
    {
      name: "Account",
      icon: Icon.Bank,
      link: "/account",
      activeClass: accountActive,
      action: () => {},
      key: 7,
    },
    {
      name: "Admin",
      icon: Icon.UserOctagon,
      link: "/admin-access",
      activeClass: adminActive,
      action: () => {},
      key: 8,
    },
    {
      name: "Community",
      icon: Icon.Bag2,
      link: "/community",
      activeClass: businessAdvisoryActive,
      action: () => {},
      key: 9,
    },
    {
      name: "POS Terminals",
      icon: Icon.Box,
      link: "/mpos",
      activeClass: mPosActive,
      action: () => {},
      key: 10,
    },
    {
      name: "Chats",
      icon: Icon.Messages,
      link: "/chats",
      activeClass: chatActive,
      action: () => {},
      key: 11,
    },
    {
      name: "Settings",
      icon: Icon.Setting2,
      link: "/settings",
      activeClass: settingActive,
      action: () => {},
      key: 12,
    },
  ];

  return (
    <>
      <MainWidget mobileDisplay={mobileDisplay}>
        <CloseBtn>
          <FeatherIcon.X onClick={() => closeNav()} />
        </CloseBtn>
        <NavLink to="/">
          <LogoImageWrap>
            {/* <LogoImage
                            width='3rem'
                            src='/images/logo.png'
                            alt='Mongoro'
                        /> */}
            <LogoImage width="10rem" src="/mongoro.svg" alt="Mongoro" />
          </LogoImageWrap>
        </NavLink>
        <NavHeader>MAIN MENU</NavHeader>
        {navList.map((item, index) => {
          return Array.isArray(access) &&
            access.length &&
            access.includes(item.key) ? (
            <NavLink to={item.link ? item.link : ""} key={index}>
              <NavItem
                className={`nav-class ${item.activeClass ? "active-nav" : ""}`}
                style={item.name === "Services" ? { border: "none" } : {}}
                onClick={() => item.action(index)}
              >
                <div>
                  {React.createElement(item.icon, {
                    size: 20,
                  })}
                  <p>{item.name}</p>
                </div>
                {item.hasOption && !openOption ? (
                  <FeatherIcon.ChevronDown onClick={() => item.action(index)} />
                ) : null}
                {openOption && index === activeItem ? (
                  <FeatherIcon.ChevronRight
                    onClick={() => {
                      setActiveItem(-1);
                      setOpenOption(false);
                    }}
                  />
                ) : null}
              </NavItem>
              {openOption && index === activeItem ? (
                <NavOption>
                  <Link to="/kyc">
                    <p>KYC</p>
                  </Link>
                  <Link to="/users">
                    <p>Users</p>
                  </Link>
                  <Link to="/audit-trails">
                    <p>Audit Trails</p>
                  </Link>
                </NavOption>
              ) : null}
            </NavLink>
          ) : null;
        })}
        {/* <NavItem
                        onClick={() => redirectAfterLogOut()}
                        className='nav-class'
                    >
                        <div>
                            <Icon.Lock1
                                color='#c82b38'
                                size={20}
                            />
                            <p
                                style={{color: '#c82b38'}}
                            >Logout</p>
                        </div>
                    </NavItem>  */}
      </MainWidget>
    </>
  );
};

export default SideBarWidget;
